// general react imports
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

// project specific files
import { ModuleTextSmall } from 'components/text/module_text_small';

type ModuleButtonType = {
  title?: string | undefined;
  children?: React.ReactNode;
  onClick?: () => void;
  text?: string;
  icon?: React.ReactNode;
  disabled?: boolean | undefined;
  sx?: SxProps;
  capitalize?: boolean;
  upperCase?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  bold?: boolean;
  gradientCard?: boolean;
  link?: string | undefined;
} & React.HTMLProps<HTMLDivElement>;

export const ModuleButton: React.FunctionComponent<ModuleButtonType> = ({
  onClick,
  text,
  icon,
  children,
  disabled,
  title,
  sx,
  capitalize,
  upperCase = true,
  variant = 'outlined',
  bold,
  gradientCard = true,
  link
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState(text);
  useEffect(() => {
    setButtonText(t(text || ''));
  }, [t, text]);

  return (
    <Button
      className={gradientCard ? 'gradient-card' : ''}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:
          (children && icon) || (text && children) || (text && icon) ? 'space-between' : 'center',
        height: '48px',
        backgroundColor: 'mode',
        ...sx
      }}
      variant={variant}
      size="large"
      disabled={disabled ? disabled : false}
      onClick={link ? () => navigate(link) : onClick}
      title={title ? title : text ? text : undefined}
      aria-label={title ? title : text ? text : undefined}
    >
      {text ? (
        <ModuleTextSmall
          capitalize={capitalize ? capitalize : true}
          sx={bold ? { color: 'primary.main', fontWeight: 'bold' } : {}}
        >
          {buttonText ? (upperCase ? buttonText.toUpperCase() : buttonText) : ''}
        </ModuleTextSmall>
      ) : null}
      {children}
      {icon}
    </Button>
  );
};
