// general react imports
import React from 'react';

import NewReleasesIcon from '@mui/icons-material/NewReleases';

// project specific files
import { ModuleColumn } from 'components/containers/module_column';
import { ModuleText } from 'components/text/module_text';

export const AdAlphaBuild = () => {
  return (
    <ModuleColumn>
      <ModuleText upperCase>WARNING! EARLY RELEASE!</ModuleText>
      <NewReleasesIcon sx={{ height: '150px', width: '150px' }} />
      <ModuleText>Platform is still under construction.</ModuleText>
      <ModuleText>Do not use it!</ModuleText>
    </ModuleColumn>
  );
};
