// general react imports
import React from 'react';

// project specific files
import { print } from 'functions/print';

type FlagImageType = {
  countryCode: string;
};

export const ModuleFlagImage: React.FunctionComponent<FlagImageType> = ({ countryCode }) => {
  const handleImageError = (e: unknown) => {
    // Handle image loading error, set ImageComponent to render default image
    print.error('Error loading image', e);
  };

  return (
    <div
      style={{
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {countryCode ? (
        <img
          loading="lazy"
          width="20"
          src={require(`assets/international_icons/${countryCode.toUpperCase()}.png`)}
          alt="[x]"
          style={{ border: '1px' }}
          onError={handleImageError}
        />
      ) : null}
    </div>
  );
};
