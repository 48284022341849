/* eslint-disable @typescript-eslint/no-explicit-any */
// general react imports
import { AxiosRequestHeaders, AxiosResponse, Method } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import url from 'url-composer';
import { appEnv } from 'app_env';

// init axios defaults
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

type handleRequestType = {
  endpoint: string;
  id?: string;
  query?: any;
  params?: any;
  data?: any;
  headers?: AxiosRequestHeaders;
  handleResponse: (arg0: AxiosResponse) => any;
  method?: Method;
};

// https://github.com/RasCarlito/url-composer
export const handleRequest = async ({
  endpoint,
  id,
  query,
  data,
  params,
  handleResponse,
  headers,
  method = 'GET'
}: handleRequestType): Promise<AxiosResponse> => {
  const url_str = url.build({
    host: appEnv.url.backend,
    path: `:endpoint/${id ? ':id' : ''}`,
    params: { endpoint, id },
    query
  });

  axios.defaults.headers.common['X-CSRF-TOKEN'] = Cookies.get('csrftoken');

  return axios({
    url: url_str,
    method,
    data,
    headers: { ...headers },
    params
  })
    .then((response) => handleResponse(response))
    .catch((error) => error);
};
