// general react imports
import React from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { Box, Typography } from '@mui/material';

// project specific files
import { icons_manager } from 'components/icons/icons_manager';

// css files
import 'styles/network_status.css';
import 'styles/blink.css';

// online check - add logic to operate offline status
export const NetworkStatus = () => {
  const netInfo = useNetInfo();

  const isNetworkOffline = (): boolean => !netInfo.isConnected || false;
  const isNetworkSlow = (): boolean => netInfo.details?.isConnectionExpensive || false;
  const isNetworkTrouble = (): boolean => isNetworkOffline() || isNetworkSlow() || false;

  return (
    <Box className="network-status-banner" sx={{ display: isNetworkTrouble() ? 'flex' : 'none' }}>
      {isNetworkOffline() ? (
        <>
          <icons_manager.network.offline
            className="blink"
            sx={{ color: 'white', paddingRight: '10px' }}
          />
          <Typography className="network-status-label">No network connection</Typography>
        </>
      ) : (
        <>
          <icons_manager.network.slow sx={{ color: 'white', paddingRight: '10px' }} />
          <Typography className="network-status-label">Slow network connection</Typography>
        </>
      )}
    </Box>
  );
};
