// general react imports
import React from 'react';
import { useSelector } from 'react-redux';

// project specific files
import { countries } from 'payloads/countries';
import { ModuleDropdown } from 'components/dropdown/dropdown';
import { set_country_content } from 'redux/slicers/settings_slicer';
import { SettingState } from 'types/setting.d';
import { ModuleDataTypeArray } from 'types/data';

export const CountrySelect = () => {
  const legal = useSelector(
    (state: { [key: string]: SettingState }) => state['settings']!.country[0]!.legal
  );
  return (
    <ModuleDropdown
      title={'Country'}
      searchable
      options={countries as unknown as ModuleDataTypeArray}
      slicer={'settings'}
      tag={'country'}
      setStoreContent={set_country_content}
      // flag
      singleSelect
      groupedSearch={['all', 'yes', 'no', 'maybe']}
      groupedTag={'legal'}
      isValid={legal !== 'no'}
      invalidText="illegal country!"
    />
  );
};
