import React from 'react';
import { TableCell, TableRow } from '@mui/material';

// project specific files
import { DropdownCheckbox } from 'components/dropdown/dropdown_checkbox';
import { ModuleText } from 'components/text/module_text';

type DropdownHeaderType = {
  label: string;
  checked?: boolean;
  onCheckboxClick?: () => void;
};

export const DropdownHeader: React.FunctionComponent<DropdownHeaderType> = ({
  label,
  checked = false,
  onCheckboxClick = () => {}
}) => {
  return (
    <TableRow component={'div'} className={'table-row'}>
      <TableCell component={'div'}>
        {checked && (
          <DropdownCheckbox checked={checked} singleSelect={false} onClick={onCheckboxClick} />
        )}
      </TableCell>
      <TableCell component={'div'} style={{ width: '100%' }}>
        <ModuleText upperCase sx={{ fontWeight: 'bold' }}>
          {label}
        </ModuleText>
      </TableCell>
      <TableCell component={'div'} />
    </TableRow>
  );
};
