import { TimezoneCityType, timeZoneCityToCountry } from './timezone_to_country';

export type CountryFromTimezoneMetaType = {
  timezone: string;
  region: string;
  city: TimezoneCityType;
  country: string;
};

// source:
// https://www.techighness.com/post/get-user-country-and-region-on-browser-with-javascript-only/
export const getCountryFromTimezone = (): CountryFromTimezoneMetaType => {
  const meta: CountryFromTimezoneMetaType = {
    timezone: '',
    region: '',
    city: '',
    country: ''
  };
  if (Intl) {
    meta.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneArray = meta.timezone.split('/')!;
    meta.region = timezoneArray[0]!;
    meta.city = timezoneArray[timezoneArray.length - 1]! as TimezoneCityType;
    meta.country = timeZoneCityToCountry[meta.city];
  }
  return meta;
};
