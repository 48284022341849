// general react imports
import React from 'react';
import { Box } from '@mui/material';
import { ModuleText } from 'components/text/module_text';

type ModuleInfoBoxType = {
  text?: React.ReactNode;
  children?: React.ReactNode;
  disableBorder?: boolean;
  className?: string;
} & React.HTMLProps<HTMLDivElement>;

export const ModuleInfoBox: React.FunctionComponent<ModuleInfoBoxType> = ({
  children,
  text,
  disableBorder,
  className
}) => {
  return (
    <Box
      className={'infobox' + className ? ` ${className}` : ''}
      style={disableBorder ? { border: '0' } : {}}
    >
      {text ? (
        <ModuleText
          upperCase
          sx={{
            padding: '15px'
          }}
        >
          {text}
        </ModuleText>
      ) : null}
      {children}
    </Box>
  );
};
