import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { DropdownCheckbox } from 'components/dropdown/dropdown_checkbox';
import { ModuleText } from 'components/text/module_text';

// project specific files

type DropdownRowType = {
  text: string;
  onClick: () => void;
  checked: boolean;
  singleSelect?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
};

export const DropdownRow: React.FunctionComponent<DropdownRowType> = ({
  text,
  onClick,
  checked,
  startAdornment,
  endAdornment,
  singleSelect = false
}) => {
  return (
    <TableRow component={'td'} sx={{ width: '100%' }}>
      {singleSelect ? null : (
        <TableCell component={'div'} onClick={onClick}>
          {startAdornment ? (
            startAdornment
          ) : (
            <DropdownCheckbox checked={checked} singleSelect={singleSelect} />
          )}
        </TableCell>
      )}

      <TableCell component={'div'} style={{ width: '100%' }} onClick={onClick}>
        <ModuleText upperCase sx={checked ? { color: 'primary.main', fontWeight: 'bold' } : {}}>
          {text}
        </ModuleText>
      </TableCell>
      {/* FIX empty cell hack to make previous cell full width */}
      {singleSelect ? <TableCell component={'div'} width={'1px'} padding="none"></TableCell> : null}
      {endAdornment ? <TableCell component={'div'}>{endAdornment}</TableCell> : null}
    </TableRow>
  );
};
