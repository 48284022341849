export const activities = [
  {
    label: 'anal',
    group: ['sex'],
    description: [
      "insertion and thrusting of the erect penis into a person's anus, or anus and rectum, for sexual pleasure.",
      'Other forms of anal sex include fingering, the use of sex toys for anal penetration, oral sex performed on the anus (anilingus), and pegging.'
    ]
  },
  {
    label: 'BDSM',
    group: ['sex'],
    description: [
      ' variety of often erotic practices or roleplaying involving bondage, discipline, dominance and submission, sadomasochism, and other related interpersonal dynamics.',
      "Activities and relationships in BDSM are often characterized by the participants' taking on roles that are complementary and involve inequality of power; thus, the idea of informed consent of both the partners is essential"
    ]
  },
  {
    label: 'group',
    group: ['sex'],
    description: ['sex involving more than two participants']
  },
  {
    label: 'handjob',
    group: ['sex'],
    description: ['manual stimulation of the penis or scrotum']
  },
  {
    label: 'massage',
    group: ['massage'],
    description: [
      "manipulation of the body's soft tissues. Massage techniques are commonly applied with hands, fingers, elbows, knees, forearms, feet or a device. ",
      'The purpose of massage is generally for the treatment of body stress or pain.'
    ]
  },
  {
    label: 'oral',
    group: ['sex'],
    description: [
      "one partner uses the tongue, mouth or throat to excite the other partner's sex organs.",
      "For males it can include placing the penis in one's mouth and for females licking the vaginal area. "
    ]
  },
  {
    label: 'one night stand',
    group: ['other'],
    description: [
      'single sexual encounter between people, at least one of whom has no desire to start a longer-term sexual or romantic relationship.'
    ]
  },
  {
    label: 'recordings',
    group: ['sex'],
    description: [
      'video recording of a sex act, usually referring to a type of amateur pornography.'
    ]
  },
  {
    label: 'roleplay',
    group: ['sex'],
    description: [
      'roleplay that has a strong erotic element. It may involve two or more people who act out roles in order to bring to life a sexual fantasy',
      'Many people regard sexual roleplay as a means of overcoming sexual inhibitions.'
    ]
  },
  {
    label: 'sex lesson',
    group: ['sex'],
    description: [
      'teaching and learning about a broad variety of topics related to sex and sexuality. ',
      "It explores values and beliefs about those topics and helps people gain the skills that are needed to navigate relationships with self, partners, and community, and manage one's own sexual health."
    ]
  },
  {
    label: 'toys',
    group: ['sex'],
    description: [
      'object or device that is primarily used to facilitate human sexual pleasure, such as a dildo, artificial vagina or vibrator.',
      'Many popular sex toys are designed to resemble human genitals, and may be vibrating or non-vibrating.'
    ]
  },
  {
    label: 'vaginal',
    group: ['sex'],
    description: [
      'sexual activity involving the insertion and thrusting of the penis into the vagina'
    ]
  },
  {
    label: 'aqua massage',
    group: ['sex', 'massage'],
    description: []
  },
  {
    label: 'duo massage',
    group: ['sex', 'massage'],
    description: []
  },
  {
    label: 'nuru massage',
    group: ['sex', 'massage'],
    description: [
      'form of erotic massage with full body contact while both the masseur or masseuse and client are nude and coated with gel, traditionally made from seaweed.',
      "Because it entails lubricated full body contact between the person giving the massage and a client with a 'happy ending', nuru massage falls under legal prohibitions against prostitution and brothels in many jurisdictions in Japan"
    ]
  },
  {
    label: 'tantra massage',
    group: ['sex', 'massage'],
    description: [
      'Erotic massage which incorporates elements from the neotantric movement in the Western world massages the primary erogenous zones of the body, those being the mouth, the phallus (penis), the vagina and the anus.',
      'Tantric massage is also known as a physical act of touch that intends to enlighten those interacting in it by harnessing sexual energy within the body.'
    ]
  },
  {
    label: 'special requests',
    group: ['other'],
    description: [
      'anything that both parties agree and have mutual consent to.',
      'Usually a VIP service.'
    ]
  }
];

export const activities_labels = activities.map((x) => x.label);
