// general react imports
import React from 'react';

// project specific files
import { ModuleButtonNavigate } from 'components/priluvia/priluvia_button_navigate';

export const TermsCard = () => {
  const title = `Terms & Conditions`;
  return <ModuleButtonNavigate icon={<></>} text={title} link="/legal/terms" />;
};
