// general react imports
import { createSlice } from '@reduxjs/toolkit';
import { LatLng } from 'leaflet';

export type PlaceType = 'Apartment' | 'Building' | 'City' | 'District' | 'State';

export interface MarkerType {
  id: string;
  label: string;
  coordinates: LatLng;
  short: string;
  type: PlaceType;
}

export interface MarkersState {
  payload: MarkerType[];
}

const initialState: MarkersState = {
  payload: []
};

export const markersSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    set_markers_content: (state, action) => {
      state.payload = action.payload;
    }
  }
});

export const { set_markers_content } = markersSlice.actions;
export const markersReducer = markersSlice.reducer;
