// general react imports
import React from 'react';

import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

// project specific files
import { ModuleColumn } from 'components/containers/module_column';
import { ModuleText } from 'components/text/module_text';

export const AdDontPrepay = () => {
  return (
    <ModuleColumn>
      <ModuleText upperCase>Don&apos;t prepay online!</ModuleText>
      <RemoveShoppingCartIcon sx={{ height: '150px', width: '150px' }} />
      <ModuleText>Paying in advance may be a THEFT!</ModuleText>
    </ModuleColumn>
  );
};
