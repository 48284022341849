// general react imports
import React from 'react';

// project specific files
import { ModuleText } from 'components/text/module_text';
import { ModuleColumn } from 'components/containers/module_column';
import { ModuleInfoBox } from 'components/containers/module_infobox';
import { icons_manager } from 'components/icons/icons_manager';

export const WarningCard = () => {
  return (
    <ModuleColumn>
      <ModuleInfoBox className="gradient-circle" disableBorder>
        {
          <icons_manager.warning
            sx={{
              color: '#D84C41',
              // minHeight: "50vh",
              height: '100%',
              maxHeight: '200px',
              width: '100%'
            }}
          />
        }
      </ModuleInfoBox>
      <ModuleText>THIS SITE IS FOR ADULTS ONLY!</ModuleText>
      <ModuleText>BY ACCESSING THIS WEBSITE,</ModuleText>
      <ModuleText>YOU CONFIRM THAT</ModuleText>
      <ModuleText>YOU ARE 18 OR OLDER</ModuleText>
    </ModuleColumn>
  );
};
