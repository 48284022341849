// general react imports
import React from 'react';
import { Stack, SxProps } from '@mui/material';

type ModuleRowType = {
  children: React.ReactNode;
  justifyContent?: string;
  sx?: SxProps;
  divider?: React.ReactNode;
  disableDivider?: boolean;
  className?: string;
} & React.HTMLProps<HTMLDivElement>;

export const ModuleRow: React.FunctionComponent<ModuleRowType> = ({
  children,
  justifyContent,
  sx,
  divider,
  disableDivider,
  className
}) => (
  <Stack
    className={'module-row' + (className ? ` ${className}` : '')}
    direction="row"
    justifyContent={justifyContent ? justifyContent : 'space-between !important'}
    divider={disableDivider ? null : divider ? divider : <div className="module-divider" />}
    sx={sx ? sx : {}}
  >
    {children}
  </Stack>
);
