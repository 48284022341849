// project specific files
import { Language } from 'types/setting';
import { languages } from 'payloads/languages';

export const getDeviceLanguage = () => navigator.language;

export const getLanguageCodeToObject = (language_code: string): Language | undefined =>
  languages.find((obj) => obj.code === language_code);

export const getDeviceLanguageObject = () => getLanguageCodeToObject(getDeviceLanguage());
