import React from 'react';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

// project specific files

type DropdownCheckboxType = {
  checked: boolean;
  singleSelect: boolean;
  onClick?: () => void;
} & React.HTMLProps<HTMLDivElement>;

export const DropdownCheckbox: React.FunctionComponent<DropdownCheckboxType> = ({
  checked,
  singleSelect,
  onClick
}) => {
  return (
    <Checkbox
      aria-labelledby={`checkbox ${checked}`}
      onClick={onClick}
      edge="start"
      icon={
        singleSelect ? (
          <RadioButtonUncheckedIcon fontSize="small" />
        ) : (
          <CheckBoxOutlineBlankIcon fontSize="small" />
        )
      }
      checkedIcon={
        singleSelect ? (
          <RadioButtonCheckedIcon fontSize="small" />
        ) : (
          <CheckBoxIcon fontSize="small" />
        )
      }
      checked={checked}
      tabIndex={-1}
      disableRipple
    />
  );
};
