import React from 'react';
import { TextField } from '@mui/material';

// project specific files
import { ModuleIconButton } from 'components/buttons/module_icon_button';
import { icons_manager } from 'components/icons/icons_manager';

type DropdownPlaceholderType = {
  title: string;
  stringValue: string;
  optionsLength: number;
  handleOpen: () => void;
  readOnly?: boolean | undefined;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  isValid: boolean | undefined;
  invalidText: string | undefined;
  disabled: boolean;
};

export const DropdownPlaceholder: React.FunctionComponent<DropdownPlaceholderType> = ({
  title,
  stringValue,
  optionsLength,
  handleOpen,
  readOnly,
  startAdornment,
  endAdornment,
  isValid,
  invalidText,
  disabled
}) => {
  const disabledCondition = (): boolean => {
    return readOnly && optionsLength <= 3 ? true : false;
  };

  return (
    <TextField
      aria-labelledby={title}
      value={stringValue.toUpperCase()}
      error={isValid === false}
      label={isValid === false && invalidText ? invalidText.toUpperCase() : title.toUpperCase()}
      onClick={handleOpen}
      InputProps={{
        startAdornment: startAdornment ? startAdornment : null,
        endAdornment: endAdornment ? (
          endAdornment
        ) : (
          <ModuleIconButton
            icon={readOnly ? null : <icons_manager.expand />}
            onClick={() => handleOpen()}
            padding={'3px'}
          />
        )
      }}
      inputProps={{
        readOnly: true,
        disabled: disabledCondition()
      }}
      disabled={disabled}
    />
  );
};
