/* eslint-disable */
import { isObject, capitalize, toUpper } from 'lodash';
import { handleRequest } from 'networking/requests';
import { ENDPOINTS } from '../endpoints';
import { PersonType } from 'types/person';
import { AxiosRequestHeaders } from 'axios';
import { Dispatch } from 'react';
import { set_profile_photos } from 'redux/slicers/profile_slicer';
import { PhotoType } from 'types/photo';

const translateDict: { [key in 'body_type' | 'hair']: { [key: string]: string } } = {
  body_type: {
    Thin: 'N',
    Regular: 'R',
    Thicc: 'C'
  },
  hair: {
    White: 'WH',
    Black: 'BK',
    Brown: 'BR',
    Blond: 'BE',
    Red: 'RE'
  }
};

const replaceArrayWithLabelInObject = (data: Partial<PersonType>) => {
  const result: any = {};

  // Iterate through each key in the data object
  for (const [key, value] of Object.entries(data)) {
    // Check if the value is an array with a single object containing a 'label' property
    if (Array.isArray(value) && isObject(value[0]) && 'label' in value[0]) {
      if (value.length === 1) {
        const label = value[0].label;
        // Replace array with label value
        result[key] = typeof label === 'string' && label.length > 0 ? capitalize(label) : label;
      } else {
        result[key] = value.map((x) =>
          // @ts-ignore
          typeof x.label === 'string' && x.label.length > 0 ? capitalize(x.label) : x.label
        );
      }
    } else {
      result[key] = value; // Retain original value if conditions are not met
    }
  }

  return result;
};

const updateProfileData = async (data: Partial<PersonType>) => {
  // if property in data is of single value with label in array,
  // replace it with this value
  const purifiedData = replaceArrayWithLabelInObject(data);

  // choices have to be of single char
  if (purifiedData['i_am']) {
    purifiedData['i_am'] = purifiedData['i_am'][0];
  }
  if (purifiedData['looking_for']) {
    purifiedData['looking_for'] = purifiedData['looking_for'].map((x: string) => x[0]);
  }
  if (purifiedData['body_type']) {
    purifiedData['body_type'] = purifiedData['body_type'][0];
  }
  // ethnicity is two chars upper case
  if (purifiedData['ethnicity']) {
    purifiedData['ethnicity'] = toUpper(purifiedData['ethnicity'].slice(0, 2));
  }
  // translate body type
  if (purifiedData['body_type']) {
    purifiedData['body_type'] = translateDict.body_type[capitalize(data.body_type![0]!.label)];
  }
  // translate hair
  if (purifiedData['hair']) {
    purifiedData['hair'] = translateDict.hair[purifiedData['hair']];
  }

  return handleRequest({
    method: 'PATCH',
    endpoint: ENDPOINTS.PERSON.UPDATE,
    data: purifiedData,
    handleResponse: () => {}
  });
};

const updateProfilePhoto = async (dispatch: Dispatch<any>, photos: PhotoType[], photo: any) =>
  handleRequest({
    method: 'POST',
    endpoint: ENDPOINTS.PHOTO,
    data: photo,
    headers: {
      'Content-Type': 'multipart/form-data'
    } as AxiosRequestHeaders,
    handleResponse: (response) => {
      dispatch(
        set_profile_photos([
          ...photos,
          {
            original: response.data.url,
            thumbnail: response.data.url,
            is_title_shot: photo.is_title_shot || photos.length === 0
          }
        ])
      );
    }
  });

const updateProfileTitleShot = async (
  dispatch: Dispatch<any>,
  photos: PhotoType[],
  photo: PhotoType
) =>
  handleRequest({
    method: 'PATCH',
    endpoint: ENDPOINTS.PHOTO,
    data: { original: photo.original, is_title_shot: photo.is_title_shot },
    handleResponse: (response) => {
      if (response.status < 400) {
        dispatch(
          set_profile_photos(
            photos.map((x) =>
              x.original === photo.original ? { ...x, is_title_shot: photo.is_title_shot } : x
            )
          )
        );
      }
    }
  });

export const update = {
  profile: {
    data: updateProfileData,
    photo: updateProfilePhoto,
    title_shot: updateProfileTitleShot
  }
};
