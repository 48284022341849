// general react imports
import React from 'react';
import { Box, InputBase, InputAdornment, IconButton } from '@mui/material';
import { onInputSlowDown } from 'functions/onClickSlowDown';
import { icons_manager } from 'components/icons/icons_manager';

type ModuleSearchBarType = {
  search_text: string;
  setSearchText: (arg0: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (arg0: any) => void;
  placeholderText?: string | undefined;
  show_locate_button?: boolean;
  endAdornment?: React.ReactNode;
};

export const ModuleSearchBar: React.FunctionComponent<ModuleSearchBarType> = ({
  placeholderText,
  search_text,
  setSearchText,
  onChange,
  endAdornment
}) => {
  return (
    <Box className="module-searchbar">
      <InputBase
        className="module-searchbar-input"
        placeholder={placeholderText ? placeholderText : 'Search...'}
        value={search_text}
        onChange={
          onChange
            ? onChange
            : (event) => {
                onInputSlowDown(() => setSearchText(event.target.value));
              }
        }
        startAdornment={
          <InputAdornment position="end">
            <IconButton type="button">
              <icons_manager.search />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {endAdornment ? endAdornment : null}
            <IconButton type="button" onClick={() => (search_text ? setSearchText('') : null)}>
              <icons_manager.close />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};

ModuleSearchBar.defaultProps = {
  show_locate_button: false
};
