// general react imports
import React from 'react';
import { Color, SxProps } from '@mui/material';

// project specific files
import { ModuleButton } from 'components/buttons/module_button';

type ModuleButtonNavigateType = {
  children?: React.ReactNode;
  color?: Color;
  onClick?: () => void;
  link?: string;
  text: string;
  icon?: React.ReactNode;
  sx?: SxProps;
  bold?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const ModuleButtonNavigate: React.FunctionComponent<ModuleButtonNavigateType> = ({
  onClick,
  link,
  text,
  icon,
  children,
  sx,
  bold,
  ...other
}) => (
  <ModuleButton
    title={text}
    onClick={onClick ? onClick : () => {}}
    text={text}
    icon={icon}
    sx={{ ...sx }}
    bold={bold ? bold : false}
    link={link}
    {...other}
  >
    {children}
  </ModuleButton>
);
