// general react imports
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { latLng, LatLng } from 'leaflet';

// project specific files
import { ClosestPlace, MapCornersType, MapState } from 'types/map';

const initialState: MapState = {
  map_corners: {
    ne: latLng(52.49448734004674, 17.119445800781254),
    sw: latLng(52.49448734004674, 17.119445800781254)
  },
  map_center_coordinates: latLng(52.3724, 4.8977),
  map_center_country_code: 'NL',
  closest_place: {
    id: 1,
    name: '',
    short: ''
  }
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    set_map_corners: (state, action: PayloadAction<MapCornersType>) => {
      state.map_corners = action.payload;
    },
    set_map_center_coordinates: (state, action: PayloadAction<LatLng>) => {
      state.map_center_coordinates = action.payload;
    },
    set_map_center_country_code: (state, action: PayloadAction<string>) => {
      state.map_center_country_code = action.payload;
    },
    set_closest_place: (state, action: PayloadAction<ClosestPlace>) => {
      state.closest_place = action.payload;
    }
  }
});

export const {
  set_map_corners,
  set_map_center_coordinates,
  set_map_center_country_code,
  set_closest_place
} = mapSlice.actions;
export const mapReducer = mapSlice.reducer;
