// general react imports
import React from 'react';
import { SxProps } from '@mui/system';

// project specific files
import { ModuleButton } from './module_button';

interface ModuleButtonNoBorderType {
  children: React.ReactNode;
  onClick: () => void;
  sx?: SxProps;
  upperCase?: boolean;
  link?: string | undefined;
}

export const ModuleButtonNoBorder = ({
  children,
  onClick,
  sx,
  upperCase,
  link
}: ModuleButtonNoBorderType) => (
  <ModuleButton
    gradientCard={false}
    upperCase={upperCase ? upperCase : false}
    onClick={onClick}
    link={link}
    sx={{
      boxShadow: 'none',
      border: 'none',
      '&.MuiButton-root:hover': { border: 'none' },
      alignItems: 'left',
      justifyContent: 'left',
      ...sx
    }}
  >
    {children}
  </ModuleButton>
);
