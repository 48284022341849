// general react imports
import React from 'react';

// project specific files
import { ModuleRow } from 'components/containers/module_row';
import { ModuleColumn } from 'components/containers/module_column';
import { Logo } from 'components/logo/logo';
import { ModuleText } from 'components/text/module_text';

export const AdWhyWatch = () => {
  return (
    <ModuleColumn>
      <ModuleRow justifyContent="left">
        <ModuleText>{'Why watch...'}</ModuleText>
      </ModuleRow>
      <Logo height="150px" />
      <ModuleRow justifyContent="right">
        <ModuleText>{`...when You can JOIN`}</ModuleText>
      </ModuleRow>
      <ModuleText>{`(concept for adult streaming services, YT, Twitch)`}</ModuleText>
    </ModuleColumn>
  );
};
