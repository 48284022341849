// general react imports
import { ThemeOptions, createTheme } from '@mui/material/styles';
import { PaletteMode, Theme } from '@mui/material';

const font = {
  fontFamily: `"Open Sans", sans-serif`,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500
};

const theme_dark: ThemeOptions = {
  typography: {
    ...font,
    body1: {
      color: '#FFFFFF'
    },
    h1: {
      color: '#FFFFFF'
    },
    h2: {
      color: '#FFFFFF'
    },
    h3: {
      color: '#FFFFFF'
    },
    h4: {
      color: '#FFFFFF'
    },
    h5: {
      color: '#FFFFFF'
    },
    h6: {
      color: '#FFFFFF'
    },
    button: {
      color: '#FFFFFF'
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#EA638B',
      light: '#121212',
      dark: '#271A3D',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#EA638B',
      light: '#121212',
      dark: '#FFFFFF',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#094507',
      light: '#121212',
      dark: '#FFFFFF',
      contrastText: '#FFFFFF'
    }
  }
  // cssOutlinedInput: {
  //   color: "#EA638C", // <!-- ADD THIS ONE
  // },
};

const theme_light: ThemeOptions = {
  typography: {
    ...font,
    body1: {
      color: '#15141A'
    },
    h1: {
      color: '#15141A'
    },
    h2: {
      color: '#15141A'
    },
    h3: {
      color: '#15141A'
    },
    h4: {
      color: '#15141A'
    },
    h5: {
      color: '#15141A'
    },
    h6: {
      color: '#15141A'
    },
    button: {
      color: '#15141A'
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#EA638B',
      light: '#FFFFFF',
      dark: '#121212',
      contrastText: '#15141A'
    },
    secondary: {
      main: '#EA638B',
      light: '#FFFFFF',
      dark: '#121212',
      contrastText: '#15141A'
    },
    success: {
      main: '#1B8E2D',
      light: '#FFFFFF',
      dark: '#121212',
      contrastText: '#15141A'
    }
  }
  // cssOutlinedInput: {
  //   color: "#FFD9DA", // <!-- ADD THIS ONE
  // },
};

export const ThemePalette = (mode: PaletteMode): Theme => {
  return mode === 'dark' ? createTheme(theme_dark) : createTheme(theme_light);
};
