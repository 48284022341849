// general react imports
import React from 'react';
import { Helmet } from 'react-helmet';

// project specific files
import { appEnv } from 'app_env';

export const PageMeta = ({
  title,
  description,
  type = 'website',
  locale = 'en_US',
  image
}: {
  title: string;
  description?: string;
  type?: string;
  locale?: string;
  image?: string;
}) => (
  <Helmet>
    <title>{`${title} | ${appEnv.name}`}</title>
    <meta name="rating" content={appEnv.ageRating} />
    {/* FB OPENGRAPH TAGS  */}
    <meta name="description" content={description || appEnv.description} />
    <meta property="og:title" content={`${title} | ${appEnv.name}`} />
    <meta property="og:type" content={type} />
    <meta property="og:locale" content={locale} />
    <meta property="og:url" content={appEnv.url.frontend} />
    <meta property="og:description" content={description || appEnv.description} />
    <meta property="fb:app_id" content={appEnv.faceBookAppID} />
    <meta property="og:image" content={image || 'logo120.png'} />
  </Helmet>
);
