import { Country } from 'types/setting';

export const countries: Array<Country> = [
  {
    code: 'AND',
    label: 'Andorra',
    phone: '+376',
    legal: 'maybe'
  },
  {
    code: 'ARE',
    label: 'United Arab Emirates',
    phone: '+971',
    legal: 'no'
  },
  {
    code: 'AFG',
    label: 'Afghanistan',
    phone: '+93',
    legal: 'no'
  },
  {
    code: 'ATG',
    label: 'Antigua and Barbuda',
    phone: '+1-268',
    legal: 'maybe'
  },
  {
    code: 'AIA',
    label: 'Anguilla',
    phone: '+1-264',
    legal: 'maybe'
  },
  {
    code: 'ALB',
    label: 'Albania',
    phone: '+355',
    legal: 'no'
  },
  {
    code: 'ARM',
    label: 'Armenia',
    phone: '+374',
    legal: 'maybe'
  },
  {
    code: 'AGO',
    label: 'Angola',
    phone: '+244',
    legal: 'no'
  },
  {
    code: 'ATA',
    label: 'Antarctica',
    phone: '+672',
    legal: 'maybe'
  },
  {
    code: 'ARG',
    label: 'Argentina',
    phone: '+54',
    legal: 'yes'
  },
  {
    code: 'ASM',
    label: 'American Samoa',
    phone: '+1-684',
    legal: 'maybe'
  },
  {
    code: 'AUT',
    label: 'Austria',
    phone: '+43',
    legal: 'yes'
  },
  {
    code: 'AUS',
    label: 'Australia',
    phone: '+61',
    legal: 'maybe'
  },
  {
    code: 'ABW',
    label: 'Aruba',
    phone: '+297',
    legal: 'maybe'
  },
  {
    code: 'ALA',
    label: 'Alland Islands',
    phone: '+358',
    legal: 'maybe'
  },
  {
    code: 'AZE',
    label: 'Azerbaijan',
    phone: '+994',
    legal: 'maybe'
  },
  {
    code: 'BIH',
    label: 'Bosnia and Herzegovina',
    phone: '+387',
    legal: 'maybe'
  },
  {
    code: 'BRB',
    label: 'Barbados',
    phone: '+1-246',
    legal: 'no'
  },
  {
    code: 'BGD',
    label: 'Bangladesh',
    phone: '+880',
    legal: 'yes'
  },
  {
    code: 'BEL',
    label: 'Belgium',
    phone: '+32',
    legal: 'yes'
  },
  {
    code: 'BFA',
    label: 'Burkina Faso',
    phone: '+226',
    legal: 'maybe'
  },
  {
    code: 'BGR',
    label: 'Bulgaria',
    phone: '+359',
    legal: 'yes'
  },
  {
    code: 'BHR',
    label: 'Bahrain',
    phone: '+973',
    legal: 'maybe'
  },
  {
    code: 'BDI',
    label: 'Burundi',
    phone: '+257',
    legal: 'maybe'
  },
  {
    code: 'BEN',
    label: 'Benin',
    phone: '+229',
    legal: 'maybe'
  },
  {
    code: 'BLM',
    label: 'Saint Barthelemy',
    phone: '+590',
    legal: 'maybe'
  },
  {
    code: 'BMU',
    label: 'Bermuda',
    phone: '+1-441',
    legal: 'maybe'
  },
  {
    code: 'BRN',
    label: 'Brunei Darussalam',
    phone: '+673',
    legal: 'maybe'
  },
  {
    code: 'BOL',
    label: 'Bolivia',
    phone: '+591',
    legal: 'yes'
  },
  {
    code: 'BRA',
    label: 'Brazil',
    phone: '+55',
    legal: 'yes'
  },
  {
    code: 'BHS',
    label: 'Bahamas',
    phone: '+1-242',
    legal: 'no'
  },
  {
    code: 'BTN',
    label: 'Bhutan',
    phone: '+975',
    legal: 'maybe'
  },
  {
    code: 'BVT',
    label: 'Bouvet Island',
    phone: '+47',
    legal: 'maybe'
  },
  {
    code: 'BWA',
    label: 'Botswana',
    phone: '+267',
    legal: 'maybe'
  },
  {
    code: 'BLR',
    label: 'Belarus',
    phone: '+375',
    legal: 'maybe'
  },
  {
    code: 'BLZ',
    label: 'Belize',
    phone: '+501',
    legal: 'yes'
  },
  {
    code: 'CAN',
    label: 'Canada',
    phone: '+1',
    legal: 'maybe'
  },
  {
    code: 'CCK',
    label: 'Cocos (Keeling) Islands',
    phone: '+61',
    legal: 'maybe'
  },
  {
    code: 'COD',
    label: 'Congo, Democratic Republic of the',
    phone: '+243',
    legal: 'maybe'
  },
  {
    code: 'CAF',
    label: 'Central African Republic',
    phone: '+236',
    legal: 'maybe'
  },
  {
    code: 'COG',
    label: 'Congo, Republic of the',
    phone: '+242',
    legal: 'maybe'
  },
  {
    code: 'CHE',
    label: 'Switzerland',
    phone: '+41',
    legal: 'yes'
  },
  {
    code: 'CIV',
    label: "Cote d'Ivoire",
    phone: '+225',
    legal: 'maybe'
  },
  {
    code: 'COK',
    label: 'Cook Islands',
    phone: '+682',
    legal: 'maybe'
  },
  {
    code: 'CHL',
    label: 'Chile',
    phone: '+56',
    legal: 'yes'
  },
  {
    code: 'CMR',
    label: 'Cameroon',
    phone: '+237',
    legal: 'maybe'
  },
  {
    code: 'CHN',
    label: 'China',
    phone: '+86',
    legal: 'no'
  },
  {
    code: 'COL',
    label: 'Colombia',
    phone: '+57',
    legal: 'yes'
  },
  {
    code: 'CRI',
    label: 'Costa Rica',
    phone: '+506',
    legal: 'yes'
  },
  {
    code: 'CUB',
    label: 'Cuba',
    phone: '+53',
    legal: 'yes'
  },
  {
    code: 'CPV',
    label: 'Cape Verde',
    phone: '+238',
    legal: 'maybe'
  },
  {
    code: 'CUW',
    label: 'Curacao',
    phone: '+599',
    legal: 'maybe'
  },
  {
    code: 'CXR',
    label: 'Christmas Island',
    phone: '+61',
    legal: 'maybe'
  },
  {
    code: 'CYP',
    label: 'Cyprus',
    phone: '+357',
    legal: 'yes'
  },
  {
    code: 'CZE',
    label: 'Czech Republic',
    phone: '+420',
    legal: 'yes'
  },
  {
    code: 'DEU',
    label: 'Germany',
    phone: '+49',
    legal: 'yes'
  },
  {
    code: 'DJI',
    label: 'Djibouti',
    phone: '+253',
    legal: 'maybe'
  },
  {
    code: 'DNK',
    label: 'Denmark',
    phone: '+45',
    legal: 'yes'
  },
  {
    code: 'DMA',
    label: 'Dominica',
    phone: '+1-767',
    legal: 'no'
  },
  {
    code: 'DOM',
    label: 'Dominican Republic',
    phone: '+1-809',
    legal: 'yes'
  },
  {
    code: 'DZA',
    label: 'Algeria',
    phone: '+213',
    legal: 'maybe'
  },
  {
    code: 'ECU',
    label: 'Ecuador',
    phone: '+593',
    legal: 'yes'
  },
  {
    code: 'EST',
    label: 'Estonia',
    phone: '+372',
    legal: 'yes'
  },
  {
    code: 'EGY',
    label: 'Egypt',
    phone: '+20',
    legal: 'no'
  },
  {
    code: 'ESH',
    label: 'Western Sahara',
    phone: '+212',
    legal: 'maybe'
  },
  {
    code: 'ERI',
    label: 'Eritrea',
    phone: '+291',
    legal: 'maybe'
  },
  {
    code: 'ESP',
    label: 'Spain',
    phone: '+34',
    legal: 'yes'
  },
  {
    code: 'ETH',
    label: 'Ethiopia',
    phone: '+251',
    legal: 'yes'
  },
  {
    code: 'FIN',
    label: 'Finland',
    phone: '+358',
    legal: 'yes'
  },
  {
    code: 'FJI',
    label: 'Fiji',
    phone: '+679',
    legal: 'maybe'
  },
  {
    code: 'FLK',
    label: 'Falkland Islands (Malvinas)',
    phone: '+500',
    legal: 'maybe'
  },
  {
    code: 'FSM',
    label: 'Micronesia, Federated States of',
    phone: '+691',
    legal: 'maybe'
  },
  {
    code: 'FRO',
    label: 'Faroe Islands',
    phone: '+298',
    legal: 'maybe'
  },
  {
    code: 'FRA',
    label: 'France',
    phone: '+33',
    legal: 'maybe'
  },
  {
    code: 'GAB',
    label: 'Gabon',
    phone: '+241',
    legal: 'maybe'
  },
  {
    code: 'GBR',
    label: 'United Kingdom',
    phone: '+44',
    legal: 'maybe'
  },
  {
    code: 'GRD',
    label: 'Grenada',
    phone: '+1-473',
    legal: 'no'
  },
  {
    code: 'GEO',
    label: 'Georgia',
    phone: '+995',
    legal: 'maybe'
  },
  {
    code: 'GUF',
    label: 'French Guiana',
    phone: '+594',
    legal: 'maybe'
  },
  {
    code: 'GGY',
    label: 'Guernsey',
    phone: '+44',
    legal: 'maybe'
  },
  {
    code: 'GHA',
    label: 'Ghana',
    phone: '+233',
    legal: 'maybe'
  },
  {
    code: 'GIB',
    label: 'Gibraltar',
    phone: '+350',
    legal: 'maybe'
  },
  {
    code: 'GRL',
    label: 'Greenland',
    phone: '+299',
    legal: 'maybe'
  },
  {
    code: 'GMB',
    label: 'Gambia',
    phone: '+220',
    legal: 'maybe'
  },
  {
    code: 'GIN',
    label: 'Guinea',
    phone: '+224',
    legal: 'maybe'
  },
  {
    code: 'GLP',
    label: 'Guadeloupe',
    phone: '+590',
    legal: 'maybe'
  },
  {
    code: 'GNQ',
    label: 'Equatorial Guinea',
    phone: '+240',
    legal: 'maybe'
  },
  {
    code: 'GRC',
    label: 'Greece',
    phone: '+30',
    legal: 'yes'
  },
  {
    code: 'SGS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '+500',
    legal: 'maybe'
  },
  {
    code: 'GTM',
    label: 'Guatemala',
    phone: '+502',
    legal: 'yes'
  },
  {
    code: 'GUM',
    label: 'Guam',
    phone: '+1-671',
    legal: 'maybe'
  },
  {
    code: 'GNB',
    label: 'Guinea-Bissau',
    phone: '+245',
    legal: 'maybe'
  },
  {
    code: 'GUY',
    label: 'Guyana',
    phone: '+592',
    legal: 'no'
  },
  {
    code: 'HKG',
    label: 'Hong Kong',
    phone: '+852',
    legal: 'maybe'
  },
  {
    code: 'HMD',
    label: 'Heard Island and McDonald Islands',
    phone: '+672',
    legal: 'maybe'
  },
  {
    code: 'HND',
    label: 'Honduras',
    phone: '+504',
    legal: 'yes'
  },
  {
    code: 'HRV',
    label: 'Croatia',
    phone: '+385',
    legal: 'no'
  },
  {
    code: 'HTI',
    label: 'Haiti',
    phone: '+509',
    legal: 'no'
  },
  {
    code: 'HUN',
    label: 'Hungary',
    phone: '+36',
    legal: 'yes'
  },
  {
    code: 'IDN',
    label: 'Indonesia',
    phone: '+62',
    legal: 'yes'
  },
  {
    code: 'IRL',
    label: 'Ireland',
    phone: '+353',
    legal: 'maybe'
  },
  {
    code: 'ISR',
    label: 'Israel',
    phone: '+972',
    legal: 'yes'
  },
  {
    code: 'IMN',
    label: 'Isle of Man',
    phone: '+44',
    legal: 'maybe'
  },
  {
    code: 'IND',
    label: 'India',
    phone: '+91',
    legal: 'yes'
  },
  {
    code: 'IOT',
    label: 'British Indian Ocean Territory',
    phone: '+246',
    legal: 'maybe'
  },
  {
    code: 'IRQ',
    label: 'Iraq',
    phone: '+964',
    legal: 'no'
  },
  {
    code: 'IRN',
    label: 'Iran, Islamic Republic of',
    phone: '+98',
    legal: 'no'
  },
  {
    code: 'ISL',
    label: 'Iceland',
    phone: '+354',
    legal: 'maybe'
  },
  {
    code: 'ITA',
    label: 'Italy',
    phone: '+39',
    legal: 'yes'
  },
  {
    code: 'JEY',
    label: 'Jersey',
    phone: '+44',
    legal: 'maybe'
  },
  {
    code: 'JAM',
    label: 'Jamaica',
    phone: '+1-876',
    legal: 'no'
  },
  {
    code: 'JOR',
    label: 'Jordan',
    phone: '+962',
    legal: 'no'
  },
  {
    code: 'JPN',
    label: 'Japan',
    phone: '+81',
    legal: 'maybe'
  },
  {
    code: 'KEN',
    label: 'Kenya',
    phone: '+254',
    legal: 'yes'
  },
  {
    code: 'KGZ',
    label: 'Kyrgyzstan',
    phone: '+996',
    legal: 'yes'
  },
  {
    code: 'KHM',
    label: 'Cambodia',
    phone: '+855',
    legal: 'no'
  },
  {
    code: 'KIR',
    label: 'Kiribati',
    phone: '+686',
    legal: 'maybe'
  },
  {
    code: 'COM',
    label: 'Comoros',
    phone: '+269',
    legal: 'maybe'
  },
  {
    code: 'KNA',
    label: 'Saint Kitts and Nevis',
    phone: '+1-869',
    legal: 'no'
  },
  {
    code: 'PRK',
    label: "Korea, Democratic People's Republic of",
    phone: '+850',
    legal: 'no'
  },
  {
    code: 'KOR',
    label: 'Korea, Republic of',
    phone: '+82',
    legal: 'no'
  },
  {
    code: 'KWT',
    label: 'Kuwait',
    phone: '+965',
    legal: 'maybe'
  },
  {
    code: 'CYM',
    label: 'Cayman Islands',
    phone: '+1-345',
    legal: 'maybe'
  },
  {
    code: 'KAZ',
    label: 'Kazakhstan',
    phone: '+7',
    legal: 'maybe'
  },
  {
    code: 'LAO',
    label: "Lao People's Democratic Republic",
    phone: '+856',
    legal: 'maybe'
  },
  {
    code: 'LBN',
    label: 'Lebanon',
    phone: '+961',
    legal: 'maybe'
  },
  {
    code: 'LCA',
    label: 'Saint Lucia',
    phone: '+1-758',
    legal: 'no'
  },
  {
    code: 'LIE',
    label: 'Liechtenstein',
    phone: '+423',
    legal: 'maybe'
  },
  {
    code: 'LKA',
    label: 'Sri Lanka',
    phone: '+94',
    legal: 'maybe'
  },
  {
    code: 'LBR',
    label: 'Liberia',
    phone: '+231',
    legal: 'no'
  },
  {
    code: 'LSO',
    label: 'Lesotho',
    phone: '+266',
    legal: 'maybe'
  },
  {
    code: 'LTU',
    label: 'Lithuania',
    phone: '+370',
    legal: 'no'
  },
  {
    code: 'LUX',
    label: 'Luxembourg',
    phone: '+352',
    legal: 'yes'
  },
  {
    code: 'LVA',
    label: 'Latvia',
    phone: '+371',
    legal: 'yes'
  },
  {
    code: 'LBY',
    label: 'Libya',
    phone: '+218',
    legal: 'maybe'
  },
  {
    code: 'MAR',
    label: 'Morocco',
    phone: '+212',
    legal: 'maybe'
  },
  {
    code: 'MCO',
    label: 'Monaco',
    phone: '+377',
    legal: 'maybe'
  },
  {
    code: 'MDA',
    label: 'Moldova, Republic of',
    phone: '+373',
    legal: 'maybe'
  },
  {
    code: 'MNE',
    label: 'Montenegro',
    phone: '+382',
    legal: 'maybe'
  },
  {
    code: 'MAF',
    label: 'Saint Martin (French part)',
    phone: '+590',
    legal: 'maybe'
  },
  {
    code: 'MDG',
    label: 'Madagascar',
    phone: '+261',
    legal: 'maybe'
  },
  {
    code: 'MHL',
    label: 'Marshall Islands',
    phone: '+692',
    legal: 'maybe'
  },
  {
    code: 'MKD',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '+389',
    legal: 'maybe'
  },
  {
    code: 'MLI',
    label: 'Mali',
    phone: '+223',
    legal: 'maybe'
  },
  {
    code: 'MMR',
    label: 'Myanmar',
    phone: '+95',
    legal: 'maybe'
  },
  {
    code: 'MNG',
    label: 'Mongolia',
    phone: '+976',
    legal: 'maybe'
  },
  {
    code: 'MAC',
    label: 'Macao',
    phone: '+853',
    legal: 'maybe'
  },
  {
    code: 'MNP',
    label: 'Northern Mariana Islands',
    phone: '+1-670',
    legal: 'maybe'
  },
  {
    code: 'MTQ',
    label: 'Martinique',
    phone: '+596',
    legal: 'maybe'
  },
  {
    code: 'MRT',
    label: 'Mauritania',
    phone: '+222',
    legal: 'maybe'
  },
  {
    code: 'MSR',
    label: 'Montserrat',
    phone: '+1-664',
    legal: 'maybe'
  },
  {
    code: 'MLT',
    label: 'Malta',
    phone: '+356',
    legal: 'yes'
  },
  {
    code: 'MUS',
    label: 'Mauritius',
    phone: '+230',
    legal: 'maybe'
  },
  {
    code: 'MDV',
    label: 'Maldives',
    phone: '+960',
    legal: 'maybe'
  },
  {
    code: 'MWI',
    label: 'Malawi',
    phone: '+265',
    legal: 'maybe'
  },
  {
    code: 'MEX',
    label: 'Mexico',
    phone: '+52',
    legal: 'yes'
  },
  {
    code: 'MYS',
    label: 'Malaysia',
    phone: '+60',
    legal: 'maybe'
  },
  {
    code: 'MOZ',
    label: 'Mozambique',
    phone: '+258',
    legal: 'maybe'
  },
  {
    code: 'NAM',
    label: 'Namibia',
    phone: '+264',
    legal: 'maybe'
  },
  {
    code: 'NCL',
    label: 'New Caledonia',
    phone: '+687',
    legal: 'maybe'
  },
  {
    code: 'NER',
    label: 'Niger',
    phone: '+227',
    legal: 'maybe'
  },
  {
    code: 'NFK',
    label: 'Norfolk Island',
    phone: '+672',
    legal: 'maybe'
  },
  {
    code: 'NGA',
    label: 'Nigeria',
    phone: '+234',
    legal: 'maybe'
  },
  {
    code: 'NIC',
    label: 'Nicaragua',
    phone: '+505',
    legal: 'yes'
  },
  {
    code: 'NLD',
    label: 'Netherlands',
    phone: '+31',
    legal: 'yes'
  },
  {
    code: 'NOR',
    label: 'Norway',
    phone: '+47',
    legal: 'maybe'
  },
  {
    code: 'NPL',
    label: 'Nepal',
    phone: '+977',
    legal: 'maybe'
  },
  {
    code: 'NRU',
    label: 'Nauru',
    phone: '+674',
    legal: 'maybe'
  },
  {
    code: 'NIU',
    label: 'Niue',
    phone: '+683',
    legal: 'maybe'
  },
  {
    code: 'NZL',
    label: 'New Zealand',
    phone: '+64',
    legal: 'yes'
  },
  {
    code: 'OMN',
    label: 'Oman',
    phone: '+968',
    legal: 'maybe'
  },
  {
    code: 'PAN',
    label: 'Panama',
    phone: '+507',
    legal: 'yes'
  },
  {
    code: 'PER',
    label: 'Peru',
    phone: '+51',
    legal: 'yes'
  },
  {
    code: 'PYF',
    label: 'French Polynesia',
    phone: '+689',
    legal: 'maybe'
  },
  {
    code: 'PNG',
    label: 'Papua New Guinea',
    phone: '+675',
    legal: 'maybe'
  },
  {
    code: 'PHL',
    label: 'Philippines',
    phone: '+63',
    legal: 'no'
  },
  {
    code: 'PAK',
    label: 'Pakistan',
    phone: '+92',
    legal: 'maybe'
  },
  {
    code: 'POL',
    label: 'Poland',
    phone: '+48',
    legal: 'yes'
  },
  {
    code: 'SPM',
    label: 'Saint Pierre and Miquelon',
    phone: '+508',
    legal: 'maybe'
  },
  {
    code: 'PCN',
    label: 'Pitcairn',
    phone: '+870',
    legal: 'maybe'
  },
  {
    code: 'PRI',
    label: 'Puerto Rico',
    phone: '+1',
    legal: 'maybe'
  },
  {
    code: 'PSE',
    label: 'Palestine, State of',
    phone: '+970',
    legal: 'maybe'
  },
  {
    code: 'PRT',
    label: 'Portugal',
    phone: '+351',
    legal: 'yes'
  },
  {
    code: 'PLW',
    label: 'Palau',
    phone: '+680',
    legal: 'maybe'
  },
  {
    code: 'PRY',
    label: 'Paraguay',
    phone: '+595',
    legal: 'yes'
  },
  {
    code: 'QAT',
    label: 'Qatar',
    phone: '+974',
    legal: 'no'
  },
  {
    code: 'REU',
    label: 'Reunion',
    phone: '+262',
    legal: 'maybe'
  },
  {
    code: 'ROU',
    label: 'Romania',
    phone: '+40',
    legal: 'no'
  },
  {
    code: 'SRB',
    label: 'Serbia',
    phone: '+381',
    legal: 'maybe'
  },
  {
    code: 'RUS',
    label: 'Russian Federation',
    phone: '+7',
    legal: 'maybe'
  },
  {
    code: 'RWA',
    label: 'Rwanda',
    phone: '+250',
    legal: 'no'
  },
  {
    code: 'SAU',
    label: 'Saudi Arabia',
    phone: '+966',
    legal: 'no'
  },
  {
    code: 'SLB',
    label: 'Solomon Islands',
    phone: '+677',
    legal: 'maybe'
  },
  {
    code: 'SYC',
    label: 'Seychelles',
    phone: '+248',
    legal: 'maybe'
  },
  {
    code: 'SDN',
    label: 'Sudan',
    phone: '+249',
    legal: 'maybe'
  },
  {
    code: 'SWE',
    label: 'Sweden',
    phone: '+46',
    legal: 'maybe'
  },
  {
    code: 'SGP',
    label: 'Singapore',
    phone: '+65',
    legal: 'yes'
  },
  {
    code: 'SHN',
    label: 'Saint Helena',
    phone: '+290',
    legal: 'maybe'
  },
  {
    code: 'SVN',
    label: 'Slovenia',
    phone: '+386',
    legal: 'yes'
  },
  {
    code: 'SJM',
    label: 'Svalbard and Jan Mayen',
    phone: '+47',
    legal: 'maybe'
  },
  {
    code: 'SVK',
    label: 'Slovakia',
    phone: '+421',
    legal: 'yes'
  },
  {
    code: 'SLE',
    label: 'Sierra Leone',
    phone: '+232',
    legal: 'maybe'
  },
  {
    code: 'SMR',
    label: 'San Marino',
    phone: '+378',
    legal: 'maybe'
  },
  {
    code: 'SEN',
    label: 'Senegal',
    phone: '+221',
    legal: 'yes'
  },
  {
    code: 'SOM',
    label: 'Somalia',
    phone: '+252',
    legal: 'maybe'
  },
  {
    code: 'SUR',
    label: 'Suriname',
    phone: '+597',
    legal: 'no'
  },
  {
    code: 'SSD',
    label: 'South Sudan',
    phone: '+211',
    legal: 'maybe'
  },
  {
    code: 'STP',
    label: 'Sao Tome and Principe',
    phone: '+239',
    legal: 'maybe'
  },
  {
    code: 'SLV',
    label: 'El Salvador',
    phone: '+503',
    legal: 'yes'
  },
  {
    code: 'SXM',
    label: 'Sint Maarten (Dutch part)',
    phone: '+1-721',
    legal: 'maybe'
  },
  {
    code: 'SYR',
    label: 'Syrian Arab Republic',
    phone: '+963',
    legal: 'maybe'
  },
  {
    code: 'SWZ',
    label: 'Swaziland',
    phone: '+268',
    legal: 'maybe'
  },
  {
    code: 'TCA',
    label: 'Turks and Caicos Islands',
    phone: '+1-649',
    legal: 'maybe'
  },
  {
    code: 'TCD',
    label: 'Chad',
    phone: '+235',
    legal: 'maybe'
  },
  {
    code: 'ATF',
    label: 'French Southern Territories',
    phone: '+262',
    legal: 'maybe'
  },
  {
    code: 'TGO',
    label: 'Togo',
    phone: '+228',
    legal: 'maybe'
  },
  {
    code: 'THA',
    label: 'Thailand',
    phone: '+66',
    legal: 'no'
  },
  {
    code: 'TJK',
    label: 'Tajikistan',
    phone: '+992',
    legal: 'maybe'
  },
  {
    code: 'TKL',
    label: 'Tokelau',
    phone: '+690',
    legal: 'maybe'
  },
  {
    code: 'TLS',
    label: 'Timor-Leste',
    phone: '+670',
    legal: 'maybe'
  },
  {
    code: 'TKM',
    label: 'Turkmenistan',
    phone: '+993',
    legal: 'maybe'
  },
  {
    code: 'TUN',
    label: 'Tunisia',
    phone: '+216',
    legal: 'maybe'
  },
  {
    code: 'TON',
    label: 'Tonga',
    phone: '+676',
    legal: 'maybe'
  },
  {
    code: 'TUR',
    label: 'Turkey',
    phone: '+90',
    legal: 'yes'
  },
  {
    code: 'TTO',
    label: 'Trinidad and Tobago',
    phone: '+1-868',
    legal: 'no'
  },
  {
    code: 'TUV',
    label: 'Tuvalu',
    phone: '+688',
    legal: 'maybe'
  },
  {
    code: 'TWN',
    label: 'Taiwan, Republic of China',
    phone: '+886',
    legal: 'maybe'
  },
  {
    code: 'TZA',
    label: 'United Republic of Tanzania',
    phone: '+255',
    legal: 'maybe'
  },
  {
    code: 'UKR',
    label: 'Ukraine',
    phone: '+380',
    legal: 'maybe'
  },
  {
    code: 'UGA',
    label: 'Uganda',
    phone: '+256',
    legal: 'no'
  },
  {
    code: 'URY',
    label: 'Uruguay',
    phone: '+598',
    legal: 'yes'
  },
  {
    code: 'UZB',
    label: 'Uzbekistan',
    phone: '+998',
    legal: 'maybe'
  },
  {
    code: 'VAT',
    label: 'Holy See (Vatican City State)',
    phone: '+379',
    legal: 'maybe'
  },
  {
    code: 'VCT',
    label: 'Saint Vincent and the Grenadines',
    phone: '+1-784',
    legal: 'no'
  },
  {
    code: 'VEN',
    label: 'Venezuela',
    phone: '+58',
    legal: 'yes'
  },
  {
    code: 'VGB',
    label: 'British Virgin Islands',
    phone: '+1-284',
    legal: 'maybe'
  },
  {
    code: 'VIR',
    label: 'US Virgin Islands',
    phone: '+1-340',
    legal: 'maybe'
  },
  {
    code: 'VNM',
    label: 'Vietnam',
    phone: '+84',
    legal: 'maybe'
  },
  {
    code: 'VUT',
    label: 'Vanuatu',
    phone: '+678',
    legal: 'maybe'
  },
  {
    code: 'WLF',
    label: 'Wallis and Futuna',
    phone: '+681',
    legal: 'maybe'
  },
  {
    code: 'WSM',
    label: 'Samoa',
    phone: '+685',
    legal: 'maybe'
  },
  {
    code: 'XK',
    label: 'Kosovo',
    phone: '+383',
    legal: 'maybe'
  },
  {
    code: 'YEM',
    label: 'Yemen',
    phone: '+967',
    legal: 'maybe'
  },
  {
    code: 'MYT',
    label: 'Mayotte',
    phone: '+262',
    legal: 'maybe'
  },
  {
    code: 'ZAF',
    label: 'South Africa',
    phone: '+27',
    legal: 'no'
  },
  {
    code: 'ZMB',
    label: 'Zambia',
    phone: '+260',
    legal: 'maybe'
  },
  {
    code: 'ZWE',
    label: 'Zimbabwe',
    phone: '+263',
    legal: 'maybe'
  },
  {
    code: 'AL',
    label: 'Alabama',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'AK',
    label: 'Alaska',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'AZ',
    label: 'Arizona',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'AR',
    label: 'Arkansas',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'CA',
    label: 'California',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'CO',
    label: 'Colorado',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'CT',
    label: 'Connecticut',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'DE',
    label: 'Delaware',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'FL',
    label: 'Florida',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'GA',
    label: 'Georgia',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'HI',
    label: 'Hawaii',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'ID',
    label: 'Idaho',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'IL',
    label: 'Illinois',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'IN',
    label: 'Indiana',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'IA',
    label: 'Iowa',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'KS',
    label: 'Kansas',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'KY',
    label: 'Kentucky',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'LA',
    label: 'Louisiana',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'ME',
    label: 'Maine',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MD',
    label: 'Maryland',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MA',
    label: 'Massachusetts',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MI',
    label: 'Michigan',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MN',
    label: 'Minnesota',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MS',
    label: 'Mississippi',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MO',
    label: 'Missouri',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'MT',
    label: 'Montana',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NE',
    label: 'Nebraska',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NV',
    label: 'Nevada',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NH',
    label: 'New Hampshire',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NJ',
    label: 'New Jersey',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NM',
    label: 'New Mexico',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NY',
    label: 'New York',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'NC',
    label: 'North Carolina',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'ND',
    label: 'North Dakota',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'OH',
    label: 'Ohio',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'OK',
    label: 'Oklahoma',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'OR',
    label: 'Oregon',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'PA',
    label: 'Pennsylvania',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'RI',
    label: 'Rhode Island',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'SC',
    label: 'South Carolina',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'SD',
    label: 'South Dakota',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'TN',
    label: 'Tennessee',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'TX',
    label: 'Texas',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'UT',
    label: 'Utah',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'VT',
    label: 'Vermont',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'VA',
    label: 'Virginia',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'WA',
    label: 'Washington',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'WV',
    label: 'West Virginia',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'WI',
    label: 'Wisconsin',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  },
  {
    code: 'WY',
    label: 'Wyoming',
    phone: '+1',
    legal: 'maybe',
    group: 'US'
  }
];
