/* eslint-disable no-console */
import { isEnvDev } from './checkEnv';

export const print = {
  error: (message: unknown, error: unknown) => {
    if (isEnvDev) {
      console.error(message, error);
    }
  },
  info: (message: unknown) => {
    if (isEnvDev) {
      console.info(message);
    }
  },
  warn: (message: unknown) => {
    if (isEnvDev) {
      console.warn(message);
    }
  }
};
