// general react imports
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// project specific files
import { ModuleTextType } from 'types/text.d';

export const ModuleText: React.FunctionComponent<ModuleTextType> = (props) => {
  const { t } = useTranslation();
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (!props.disableTranslation && typeof props.children === 'string') {
      setText(t(props.children));
    }
  }, [props.children, props.disableTranslation, t]);

  const sizeStyle =
    props.size === 'small'
      ? {
          marginBottom: '2px'
        }
      : {};

  return (
    <Typography
      className={props.className || ''}
      sx={{
        textAlign: props.align || 'left',
        textTransform: props.capitalize ? 'capitalize' : props.upperCase ? 'uppercase' : 'none',
        ...sizeStyle,
        ...props.sx
      }}
    >
      {typeof props.children === 'string' ? text : props.children}
    </Typography>
  );
};
