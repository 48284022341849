// general react imports
import React from 'react';
import { Box } from '@mui/material';

// project specific files
import { PanelHeader } from 'panel/panel_header';

export const PanelContent = ({
  headerText,
  children,
  hideHeader
}: {
  headerText: string | undefined;
  children: any;
  hideHeader: boolean | undefined;
}) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {hideHeader ? null : <PanelHeader text={headerText} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }}
      >
        {children}
      </Box>
    </div>
  );
};
