// general react imports
import { createSlice } from '@reduxjs/toolkit';

// types
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IllegalState {
  visibility: boolean;
  country_name: string;
}

const initialState: IllegalState = {
  visibility: false,
  country_name: ''
};

export const illegalSlice = createSlice({
  name: 'illegal',
  initialState,
  reducers: {
    hide_illegal: (state) => {
      state.visibility = false;
    },
    show_illegal: (state) => {
      state.visibility = true;
    },
    set_country_name: (state, action: PayloadAction<string>) => {
      state.country_name = action.payload;
    }
  }
});

export const { hide_illegal, show_illegal, set_country_name } = illegalSlice.actions;
export const illegalReducer = illegalSlice.reducer;
