// general react imports
import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Initialize i18next
export const initLanguages = () => {
  i18n
    .use(HttpBackend) // Load translations from a backend
    .use(initReactI18next) // Pass the instance to react-i18next
    .init({
      lng: 'en', // default language
      fallbackLng: 'en', // fallback language if translation is missing
      backend: {
        loadPath: '/assets/dicts/{{lng}}.json' // Path to load JSON files
      },
      interpolation: {
        escapeValue: false // React already escapes values by default
      }
    });
};
