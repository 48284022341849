// general react imports
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FavouriteState {
  people: { [key: string]: string | undefined };
  places: { [key: string]: string | undefined };
}

const initialState: FavouriteState = {
  people: {},
  places: {}
};

export const favouriteSlice = createSlice({
  name: 'favourite',
  initialState: initialState,
  reducers: {
    set_favourite: (state, action: PayloadAction<{ id: string; slicer: 'people' | 'places' }>) => {
      if (action.payload.id in state[action.payload.slicer]) {
        // remove payload from favourites
        delete state[action.payload.slicer][action.payload.id];
      } else {
        // add payload to favourites
        state[action.payload.slicer][action.payload.id] = undefined;
      }
    }
  }
});

export const { set_favourite } = favouriteSlice.actions;
export const favouriteReducer = favouriteSlice.reducer;
