// general react imports
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project specific files
import { ModuleDropdown } from 'components/dropdown/dropdown';
import { set_language_content } from 'redux/slicers/settings_slicer';
import { languages } from 'payloads/languages';
import { backend } from 'networking/rest/requests';
import { SettingState } from 'types/setting.d';
import { print } from 'functions/print';

export const LanguageSelect = () => {
  const dispatch = useDispatch();
  // listen to changes on language value
  const language = useSelector((state: { [key: string]: SettingState }) => {
    return state['settings']!.language;
  });

  // download dictionary on language change
  // load data from API
  useEffect(() => {
    print.info('checking language');
    backend.get.single
      .language(dispatch, language[0]!.code)
      .then((res) => print.info(res))
      .catch((e) => print.error('failed', e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <ModuleDropdown
      title={'Language'}
      options={languages}
      slicer={'settings'}
      tag={'language'}
      setStoreContent={set_language_content}
      // flag
      singleSelect
      searchable
    />
  );
};
