// check if page is being accessed by GoogleBot web crawler for indexing purposes
export const botUserAgentsArray = [
  'bot',
  'crawling',
  'spider',
  'crawler',
  'google',
  'lighthouse',
  'insights',
  'baidu',
  'bing',
  'msn',
  'teoma',
  'slurp',
  'yandex',
  'inspectiontool',
  'python',
  'aiohttp',
  'httpx',
  'perl',
  'httpunit',
  'nutch',
  'go-http-client',
  'phpcrawl',
  'biglotron',
  'convera',
  'gigablast',
  'ia_archiver',
  'webmon',
  'httrack',
  'grub.org',
  'netresearchserver',
  'speedy',
  'fluffy',
  'findlink',
  'panscient',
  'ips-agent',
  'yanga',
  'cyberpatrol',
  'postrank',
  'turnitin',
  'page2rss',
  'linkdex',
  'ezooms',
  'heritrix',
  'findthatfile',
  'europarchive.org',
  'mappydata',
  'eright',
  'apercite',
  'aboundex',
  'summify',
  'ec2linkfinder',
  'facebookexternalhit',
  'yeti',
  'retrevopageanalyzer',
  'sogou',
  'wotbox',
  'ichiro',
  'drupact',
  'coccoc',
  'integromedb',
  'proximic',
  'changedetection',
  'binlar',
  'admantx',
  'megaindex',
  'ltx71',
  'qwantify',
  'lipperhey',
  'addthis',
  'metauri',
  'scrapy',
  'capsulechecker',
  'sonic',
  'sysomos',
  'trove',
  'deadlinkchecker',
  'embedly',
  'iskanie',
  'skypeuripreview',
  'whatsapp',
  'electricmonk',
  'phantomjs',
  'jetslide',
  'newsharecounts',
  'tineye',
  'linkarchiver',
  'dcrawl',
  'snacktory',
  'okhttp',
  'nuzzel',
  'omgili',
  'pocketparser',
  'muckrack',
  'netcraftsurveyagent',
  'jetty',
  'upflow',
  'thinklab',
  'twurly',
  'mastodon',
  'brandverity',
  'ezid',
  'genieo',
  'meltwaternews',
  'moreover',
  'scoutjet',
  'seoscanners',
  'hatena',
  'adscanner',
  'netvibes',
  'btwebclient',
  'disqus',
  'feedly',
  'fever',
  'flipboardproxy',
  'vkshare',
  'dareboost',
  'feedspot',
  'seokicks',
  'tracemyfile',
  'zgrab',
  'datafeedwatch',
  'zabbix',
  'axios',
  'pulsepoint',
  'wordupinfosearch',
  'webdatastats',
  'httpurlconnection',
  'outbrain',
  'feedvalidator',
  'blackboard',
  'bazqux',
  'twingly',
  'rivva',
  'anyevent',
  'clickagy',
  'hubspot',
  'headlesschrome',
  'simplescraper',
  'fedoraplanet',
  'friendica',
  'nextcloud',
  'datanyze',
  'trendsmapresolver',
  'tweetedtimes',
  'gwene',
  'simplepie',
  'searchatlas',
  'superfeedr',
  'pandalytics',
  'seewithkids',
  'cincraw',
  'freshrss',
  'viber',
  'virustotal',
  'ioncrawl',
  'feedbin',
  'ruxitsynthetic',
  'seolizer',
  'synapse',
  'deepnoc',
  'hypestat',
  'iframely',
  'metainspector',
  'lkxscan',
  'opengraphcheck',
  'macocu',
  'newsblur',
  'inoreader',
  'netsystemsresearch',
  'pagething'
];
