// general react imports
import React from 'react';
import { Stack, SxProps } from '@mui/material';

type ModuleColumnType = {
  children: React.ReactNode;
  justifyContent?: string;
  sx?: SxProps;
  divider?: React.ReactNode;
  disableDivider?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const ModuleColumn: React.FunctionComponent<ModuleColumnType> = (props) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  <Stack
    sx={{ ...props.sx }}
    {...props}
    className={'module-column'}
    direction="column"
    justifyContent={props.justifyContent || 'center'}
    divider={
      props.disableDivider ? null : props.divider ? (
        props.divider
      ) : (
        <div className="module-divider" />
      )
    }
  />
);
