// general react imports
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// project specific files
import { ModuleBox } from 'components/containers/module_box';

// Lazy load the component that displays the webm video
// const LazyVideoPlayer = lazy(() => import('components/video_player/video_player'));
// const logo_animated_url = appEnv.url + '/logo_animated.webm';

export const LoadingIndicator = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <ModuleBox
      sx={{
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {isLoading ? <CircularProgress /> : null}
    </ModuleBox>
  );
};
