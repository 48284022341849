// project specific files
import { botUserAgentsArray } from './botUserAgentsArray';

// Check for features typically present in real browsers
export const isReal =
  window.navigator &&
  'userAgent' in navigator &&
  'appCodeName' in navigator &&
  'appName' in navigator &&
  'appVersion' in navigator &&
  'platform' in navigator &&
  'product' in navigator &&
  'vendor' in navigator &&
  'language' in navigator &&
  'languages' in navigator &&
  'onLine' in navigator &&
  'cookieEnabled' in navigator &&
  'javaEnabled' in navigator &&
  'doNotTrack' in navigator &&
  'hardwareConcurrency' in navigator &&
  'maxTouchPoints' in navigator &&
  'mimeTypes' in navigator &&
  'plugins' in navigator &&
  'productSub' in navigator &&
  'vendorSub' in navigator &&
  'requestAnimationFrame' in window &&
  'indexedDB' in window &&
  'sessionStorage' in window &&
  'localStorage' in window &&
  'WebSocket' in window;

// check if is bot in order: webdriver, if not is real, and finally if agent is on bot list
export const isBot =
  navigator.webdriver ||
  !isReal ||
  botUserAgentsArray.some((botAgent) => navigator.userAgent.toLowerCase().includes(botAgent));
