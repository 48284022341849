// general react imports
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project specific files
import { ReactComponent as IconStop } from 'assets/legal/icon_stop.svg';
import { show_illegal, hide_illegal, set_country_name } from 'redux/slicers/illegal_slicer';
import { ModuleDialog } from 'components/containers/module_dialog';
import { ModuleInfoBox } from 'components/containers/module_infobox';
import { ModuleText } from 'components/text/module_text';
import { appEnv } from 'app_env';

export const showIllegalDialog = (dispatch: any, country_name: string) => {
  dispatch(set_country_name(country_name));
  dispatch(show_illegal());
};

export const IllegalCountryDialog = () => {
  const dispatch = useDispatch();
  const country_name = useSelector(
    (state: { [key: string]: any }) => state['illegal'].country_name
  );
  const visibility = useSelector((state: { [key: string]: any }) => state['illegal'].visibility);

  return (
    <ModuleDialog
      title="Legal warning!"
      is_opened={visibility}
      handleClose={() => dispatch(hide_illegal())}
    >
      <ModuleInfoBox disableBorder>
        {<IconStop sx={{ color: 'primary.main', minHeight: '50vh', width: '100%' }} />}
      </ModuleInfoBox>
      <ModuleText upperCase>
        {appEnv.name} does not provide its service in {country_name} due to legal reasons.
      </ModuleText>
      <ModuleText upperCase>Please change country to continue.</ModuleText>
    </ModuleDialog>
  );
};
