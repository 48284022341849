// general react imports
import React from 'react';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import DangerousIcon from '@mui/icons-material/Dangerous';

import { LegalType } from 'types/legal.d';

type LegalIconType = {
  choice: LegalType;
};

export const LegalIcon: React.FunctionComponent<LegalIconType> = ({ choice }) => {
  return choice === 'yes' ? (
    <VerifiedUserIcon sx={{ color: '#008000' }} />
  ) : choice === 'no' ? (
    <DangerousIcon sx={{ color: '#990000' }} />
  ) : (
    <GppMaybeIcon sx={{ color: 'orange' }} />
  );
};
