// general react imports
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// project specific files
import { searchReducer } from 'redux/slicers/search_slicer';
import { legalReducer } from 'redux/slicers/legal_slicer';
import { illegalReducer } from 'redux/slicers/illegal_slicer';
import { settingsReducer } from 'redux/slicers/settings_slicer';
import { filterReducer } from 'redux/slicers/filter_slicer';
import { favouriteReducer } from 'redux/slicers/favourite_slicer';
import { peopleReducer } from 'redux/slicers/people_slicer';
import { placesReducer } from 'redux/slicers/places_slicer';
import { profileReducer } from 'redux/slicers/profile_slicer';
import { announcementReducer } from 'redux/slicers/announcement_slicer';
import { reportReducer } from 'redux/slicers/report_slicer';
import { offersReducer } from 'redux/slicers/offers_slicer';
import { statsReducer } from 'redux/slicers/stats_slicer';
import { markersReducer } from 'redux/slicers/markers_slicer';
import { mapReducer } from 'redux/slicers/map_slicer';
import { isEnvDev } from 'functions/checkEnv';
import { mailReducer } from './slicers/mail_slicer';
import { print } from 'functions/print';

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  search: searchReducer,
  legal: legalReducer,
  illegal: illegalReducer,
  settings: settingsReducer,
  filter: filterReducer,
  favourite: favouriteReducer,
  people: peopleReducer,
  places: placesReducer,
  profile: profileReducer,
  announcement: announcementReducer,
  report: reportReducer,
  offers: offersReducer,
  stats: statsReducer,
  markers: markersReducer,
  map: mapReducer,
  mail: mailReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: isEnvDev,
  middleware: [thunk]
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export const clearReduxStore = () => persistor.purge();

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  print.info('Offline cache cleared!');
  clearReduxStore();
  print.info('Redux store cleared!');
};
