// project specific files
import { countries } from 'payloads/countries';
import { LegalType } from 'types/legal';
import { getCountryFromTimezone } from './country_from_timezone';

export const checkIfCountryIsLegal = (country_code: string): LegalType => {
  const country = countries.find((obj) => obj.code === country_code);
  return country ? country.legal : 'no';
};

export const getCountryCodeFromName = (country_name: string) =>
  countries.find((obj) => obj.label === country_name)?.code;

export const getCountryFromName = (country_name: string) =>
  countries.find((obj) => obj.label === country_name);

export const getDeviceCountry = () => {
  const device_country_name = getCountryFromTimezone().country;
  return device_country_name ? getCountryFromName(device_country_name) : undefined;
};
