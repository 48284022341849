// general react imports
import React from 'react';
import { Box, SxProps } from '@mui/material';

interface ModuleBoxType {
  children: React.ReactNode;
  padding?: boolean;
  sx?: SxProps | undefined;
}

export const ModuleBox = ({ children, padding, sx }: ModuleBoxType) => {
  return (
    <Box
      className="module-box "
      sx={{
        padding: padding ? '0 15px 0px 15px' : 0,
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
