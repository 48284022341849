// general react imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, InputAdornment, Stack } from '@mui/material';

import { differenceWith } from 'lodash';
import { isEqual } from 'lodash';

// project specific files
import { ModuleDialog } from 'components/containers/module_dialog';
import { DropdownTable } from 'components/dropdown/dropdown_table';
import { PersonType } from 'types/person.d';
import { SettingState } from 'types/setting.d';
import { ModuleDataTypeArray } from 'types/data.d';
import { DropdownPlaceholder } from 'components/dropdown/dropown_placeholder';
import { ModuleFlagImage } from 'components/module/module_flag_image';
import { ModuleSearchBar } from 'components/searchbar/module_searchbar';
import { DropdownGroupFilter } from './dropdown_groupfilter';
import { arrayToString } from 'functions/arrayToString';

// css files
import 'styles/dropdown.css';

type ModuleDropdownType = {
  options: ModuleDataTypeArray;
  title: string;
  slicer?: string;
  tag?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStoreContent?: any;
  flag?: boolean | undefined;
  singleSelect?: boolean | undefined;
  width?: string;
  maxWidth?: string;
  visible?: boolean;
  readOnly?: boolean;
  searchable?: boolean;
  startAdornmentInput?: React.ReactNode;
  endAdornmentInput?: React.ReactNode;
  endAdornmentRow?: (index: number) => React.ReactNode;
  placeholderText?: string | undefined;
  groupedSearch?: Array<string> | undefined;
  groupedTag?: string | undefined;
  isValid?: boolean;
  invalidText?: string;
  property?: string;
  disabled?: boolean;
};

export const ModuleDropdown: React.FunctionComponent<ModuleDropdownType> = ({
  options,
  title = '',
  singleSelect,
  slicer,
  tag,
  width,
  maxWidth,
  visible,
  readOnly,
  searchable,
  startAdornmentInput,
  endAdornmentInput,
  endAdornmentRow,
  placeholderText,
  setStoreContent,
  flag,
  groupedSearch,
  groupedTag,
  isValid,
  invalidText,
  property = 'label',
  disabled = false
}) => {
  /**
   * dropdown operates in two modes: readOnly and selection
   * if readonly, slicer + tag + setStoreContent are not required
   * if selection, slicer + tag + setStoreContent are required
   */
  if (!readOnly) {
    if (!slicer || !tag || !setStoreContent) {
      throw new Error(`slicer, tag and setStoreContent are required! Dropdown name: ${title}`);
    }
  }

  const dispatch = useDispatch();

  const value = useSelector((state: { [key: string]: PersonType }) => {
    if (readOnly) {
      // show options provided to dropdown
      return options;
    } else {
      // connect to store
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return state[slicer][tag];
    }
  });
  const single_select_single_click = useSelector((state: { [key: string]: SettingState }) => {
    return state['settings']!.single_select_single_click;
  });

  const [open, setOpen] = useState(false);
  const [selected_values, setSelectedValues] = useState<ModuleDataTypeArray>(value);
  const [stringValue, setStringValue] = useState<string>('');

  const saveToStore = () => {
    if (!readOnly) {
      dispatch(setStoreContent(selected_values));
    }
  };

  useEffect(() => {
    setStringValue(arrayToString(selected_values, property, 3));
    // TODO FIX WITHOUT THIS LINE STATE IS ONE INPUT BEHIND!
    saveToStore();
  }, [selected_values]);

  // ============================ SEARCH =========================
  // the value of the search field
  const [search_text, setSearchText] = useState('');
  // the search result
  const [searched_options, setSearchedOptions] = useState(options);
  // set search results
  const search = (keyword: string) => {
    const keyword_trimmed = keyword.replace(/\s+/g, '').toLowerCase();
    if (keyword_trimmed !== '') {
      const results = options.filter((option) => {
        return String(option.label).replace(/\s+/g, '').toLowerCase().includes(keyword_trimmed);
      });
      setSearchedOptions(results);
    } else {
      // If the text field is empty, show all options
      setSearchedOptions(options);
    }

    setSearchText(keyword);
  };
  // ==============================================================

  const handleClickCheck = async (new_value: ModuleDataTypeArray) => {
    if (!readOnly) {
      if (singleSelect) {
        setSelectedValues(new_value);
      } else {
        let results = [];
        if (selected_values.some((obj) => obj.label === new_value[0]!.label)) {
          // value is already selected, remove it from the array
          results = selected_values.filter((obj) => obj.label !== new_value[0]!.label);
        } else {
          // value is not selected, add it to the array
          results = [...selected_values];
          results.push(new_value[0]!);
        }
        results.sort((a, b) => String(a.label).localeCompare(String(b.label)));
        setSelectedValues(results);
      }
      if (singleSelect && single_select_single_click) {
        handleClose();
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    saveToStore();
    setOpen(false);
  };

  return (
    <Box
      sx={{
        maxWidth: maxWidth ? maxWidth : '600px',
        width: width ? width : '100%',
        display: visible === false ? 'none' : '',
        paddingTop: '15px'
      }}
    >
      <DropdownPlaceholder
        title={title}
        stringValue={stringValue}
        optionsLength={options.length}
        handleOpen={handleOpen}
        readOnly={readOnly}
        endAdornment={endAdornmentInput}
        startAdornment={
          startAdornmentInput ? (
            startAdornmentInput
          ) : flag && singleSelect ? (
            <InputAdornment position="start">
              <ModuleFlagImage countryCode={String(selected_values[0]!['code'])} />
            </InputAdornment>
          ) : undefined
        }
        isValid={isValid}
        invalidText={invalidText}
        disabled={disabled}
      />
      <ModuleDialog
        disableBackdrop={false}
        title={title}
        is_opened={open}
        handleClose={() => handleClose()}
        header={
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="left"
            sx={{
              backgroundColor: 'primary.light',
              padding: '0 16px 0 16px'
            }}
            divider={<div style={{ height: '10px' }} />}
          >
            {searchable && (
              <ModuleSearchBar
                search_text={search_text}
                setSearchText={search}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: { target: { value: any } }) => search(e.target.value)}
                placeholderText={placeholderText}
              />
            )}
            {groupedSearch && (
              <DropdownGroupFilter
                groupedSearch={groupedSearch}
                groupedTag={groupedTag!}
                options={options}
                setSearchedOptions={setSearchedOptions}
              />
            )}
          </Stack>
        }
      >
        <DropdownTable
          label={title}
          columns={[
            { key: 'select', label: 'Select' },
            { key: 'label', label: 'Label' },
            { key: 'info', label: 'Info' }
          ]}
          data={searched_options}
          singleSelect={singleSelect}
          handleClickCheck={handleClickCheck}
          selected_values={selected_values}
          flag={flag}
          endAdornmentRow={endAdornmentRow!}
          selectAll={() => setSelectedValues(searched_options)}
          deselectAll={() =>
            setSelectedValues(differenceWith(selected_values, searched_options, isEqual))
          }
        />
      </ModuleDialog>
    </Box>
  );
};
