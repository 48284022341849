// general react imports
import React from 'react';

// project specific files
import { ModuleRow } from 'components/containers/module_row';
import { ModuleColumn } from 'components/containers/module_column';
import { Logo } from 'components/logo/logo';
import { ModuleText } from 'components/text/module_text';

export const AdWhyDate = () => {
  return (
    <ModuleColumn>
      <ModuleRow justifyContent="left">
        <ModuleText>{'Why date...'}</ModuleText>
      </ModuleRow>
      <Logo height="150px" />
      <ModuleRow justifyContent="right">
        <ModuleText>{`...when You can LOVE`}</ModuleText>
      </ModuleRow>
      <ModuleText>{`(concept for dating sites, famous romantic landmarks, pubs & restaurants)`}</ModuleText>
    </ModuleColumn>
  );
};
