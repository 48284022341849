// general react imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// project specific files
import { ModuleIconButton } from 'components/buttons/module_icon_button';
import { ModuleRow } from 'components/containers/module_row';
import { ModuleText } from 'components/text/module_text';
import { icons_manager } from 'components/icons/icons_manager';

export const PanelHeader = ({ text }: { text: string | undefined }) => {
  const navigate = useNavigate();

  return (
    <ModuleRow>
      <ModuleIconButton icon={<icons_manager.back />} onClick={() => navigate(-1)} />

      <ModuleText sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        {text?.toUpperCase()}
      </ModuleText>

      <ModuleIconButton icon={<icons_manager.close />} link="/map" />
    </ModuleRow>
  );
};
