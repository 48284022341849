// general react imports
import { createSlice } from '@reduxjs/toolkit';

export interface AnnouncementState {
  visibility: boolean;
}

const initialState: AnnouncementState = {
  visibility: false
};

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    show_announcement: (state) => {
      state.visibility = true;
    },
    hide_announcement: (state) => {
      state.visibility = false;
    }
  }
});

export const { show_announcement, hide_announcement } = announcementSlice.actions;
export const announcementReducer = announcementSlice.reducer;
