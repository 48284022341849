// general react imports
import { createSlice } from '@reduxjs/toolkit';
import { LatLng, latLng } from 'leaflet';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { PaletteMode } from '@mui/material';
import { Country, Language, SettingState } from 'types/setting.d';

const initialState: SettingState = {
  language: [{ code: 'en', label: 'english' }],
  country: [
    {
      code: 'GB',
      label: 'United Kingdom',
      phone: '44',
      legal: 'maybe'
    }
  ],
  coordinates: latLng(52.3724, 4.8977),
  mode: 'dark',
  single_select_single_click: true,
  phone_country: [
    {
      code: 'GB',
      label: 'United Kingdom',
      phone: '44',
      legal: 'maybe'
    }
  ]
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    set_language_content: (state, action: PayloadAction<Language[]>) => {
      state.language = action.payload;
    },
    set_country_content: (state, action: PayloadAction<Country[]>) => {
      state.country = action.payload;
      state.phone_country = action.payload;
    },
    set_location_content: (state, action: PayloadAction<LatLng>) => {
      state.coordinates = action.payload;
    },
    set_mode: (state, action: PayloadAction<PaletteMode>) => {
      state.mode = action.payload;
    },
    set_single_select_single_click: (state) => {
      state.single_select_single_click = !state.single_select_single_click;
    },
    set_phone_country: (state, action: PayloadAction<Country[]>) => {
      state.phone_country = action.payload;
    }
  }
});

export const {
  set_language_content,
  set_country_content,
  set_location_content,
  set_mode,
  set_single_select_single_click,
  set_phone_country
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
