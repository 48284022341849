import React from 'react';
import { Params, redirect, useLoaderData } from 'react-router-dom';
import { id } from 'networking/code_id/code_id';
import { PeoplePanel, PlacesPanel } from 'routes_controller';

// This function checks the param and redirects based on the pattern
export const handleShortCode = ({ params }: { params: Params<string> }) => {
  const short_code = params['code'] || ''; // Extract the code from the URL
  if (id.verify(short_code)) {
    const decoded_id = id.decode(short_code);
    const control_digit = decoded_id % 10;

    switch (control_digit) {
      case id.ID_CODE_ENDING.PLACE:
        return { panelType: 'place', offer_short_code: short_code };
      case id.ID_CODE_ENDING.PERSON:
        return { panelType: 'person', offer_short_code: short_code };
      default:
        return redirect('/error');
    }
  } else {
    return redirect('/error');
  }
};

// This component will be rendered by the route
export const ShortCodePage = () => {
  const { panelType, offer_short_code } = useLoaderData() as {
    panelType: string;
    offer_short_code: string;
  };

  if (panelType === 'person') {
    return <PeoplePanel offer_short_code={offer_short_code} />;
  } else if (panelType === 'place') {
    return <PlacesPanel offer_short_code={offer_short_code} />;
  } else {
    return <div>Error: Invalid short code</div>;
  }
};
