import { handleRequest } from 'networking/requests';
import { ENDPOINTS } from '../endpoints';
import { Dispatch } from 'react';
import { set_profile_photos } from 'redux/slicers/profile_slicer';
import { PhotoType } from 'types/photo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteProfilePhoto = async (dispatch: Dispatch<any>, photos: PhotoType[], photo: PhotoType) =>
  handleRequest({
    method: 'DELETE',
    endpoint: ENDPOINTS.PHOTO,
    data: { original: photo.original },
    handleResponse: (response) => {
      if (response.status < 400) {
        dispatch(set_profile_photos(photos.filter((x) => x.original !== photo.original)));
      }
    }
  });

export const deleteOperations = {
  profile: {
    photo: deleteProfilePhoto
  }
};
