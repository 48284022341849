// general react imports
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import loadable from '@loadable/component';

// project specific files
import { Panel } from 'panel/panel';
import { PageMeta } from 'networking/seo/page_meta';
import { set_filter_activity } from 'redux/slicers/filter_slicer';
import { set_profile_activity } from 'redux/slicers/profile_slicer';
import { appEnv } from 'app_env';
import { handleShortCode, ShortCodePage } from 'routes/short_code_route';

const MenuRoute = loadable(() => import('routes/menu_route'), {
  resolveComponent: (module) => module.MenuRoute
});
const FilterRoute = loadable(() => import('routes/filter_route'), {
  resolveComponent: (module) => module.FilterRoute
});
const OffersRoute = loadable(() => import('routes/offers_route'), {
  resolveComponent: (module) => module.OffersRoute
});
const SettingsRoute = loadable(() => import('routes/settings_route'), {
  resolveComponent: (module) => module.SettingsRoute
});
const AboutRoute = loadable(() => import('routes/about_route'), {
  resolveComponent: (module) => module.AboutRoute
});
const ContactRoute = loadable(() => import('routes/contact_route'), {
  resolveComponent: (module) => module.ContactRoute
});
const PeopleRoute = loadable(() => import('routes/people_route'), {
  resolveComponent: (module) => module.PeopleRoute
});
const PlacesRoute = loadable(() => import('routes/places_route'), {
  resolveComponent: (module) => module.PlacesRoute
});
const ProfileRoute = loadable(() => import('routes/profile_route'), {
  resolveComponent: (module) => module.ProfileRoute
});
const LoginRoute = loadable(() => import('routes/login_route'), {
  resolveComponent: (module) => module.LoginRoute
});
const RegisterRoute = loadable(() => import('routes/register_route'), {
  resolveComponent: (module) => module.RegisterRoute
});
const ActivityDescriptionRoute = loadable(() => import('routes/activity_route'), {
  resolveComponent: (module) => module.ActivityDescriptionRoute
});
const PremiumRoute = loadable(() => import('routes/premium_route'), {
  resolveComponent: (module) => module.PremiumRoute
});
const DocsRoute = loadable(() => import('routes/docs_route'), {
  resolveComponent: (module) => module.DocsRoute
});
const StatsRoute = loadable(() => import('routes/stats_route'), {
  resolveComponent: (module) => module.StatsRoute
});
const ErrorRoute = loadable(() => import('routes/error_route'), {
  resolveComponent: (module) => module.ErrorRoute
});
const EulaRoute = loadable(() => import('routes/legal/eula_route'), {
  resolveComponent: (module) => module.EulaRoute
});
const HelpRoute = loadable(() => import('routes/help_route'), {
  resolveComponent: (module) => module.HelpRoute
});
const PrivacyPolicyRoute = loadable(() => import('routes/legal/privacy_policy_route'), {
  resolveComponent: (module) => module.PrivacyPolicyRoute
});
const LawRoute = loadable(() => import('routes/legal/law_route'), {
  resolveComponent: (module) => module.LawRoute
});
const TermsRoute = loadable(() => import('routes/legal/terms_route'), {
  resolveComponent: (module) => module.TermsRoute
});
const LegalRoute = loadable(() => import('routes/legal/legal_route'), {
  resolveComponent: (module) => module.LegalRoute
});
const MailRoute = loadable(() => import('routes/mail_route'), {
  resolveComponent: (module) => module.MailRoute
});
const NowRoute = loadable(() => import('routes/now_route'), {
  resolveComponent: (module) => module.NowRoute
});
const MapRoute = loadable(() => import('routes/map_route'), {
  resolveComponent: (module) => module.MapRoute
});
const EventRoute = loadable(() => import('routes/event_route'), {
  resolveComponent: (module) => module.EventRoute
});
const EduRoute = loadable(() => import('routes/edu_route'), {
  resolveComponent: (module) => module.EduRoute
});

const errorElement = <ErrorRoute message={'An error occured'} />;

export const PeoplePanel = ({
  offer_short_code = ''
}: {
  offer_short_code?: string | undefined;
}) => (
  <Panel hideHeader>
    <PeopleRoute offer_short_code={offer_short_code} />
  </Panel>
);

export const PlacesPanel = ({
  offer_short_code = ''
}: {
  offer_short_code?: string | undefined;
}) => (
  <Panel hideHeader>
    <PlacesRoute offer_short_code={offer_short_code} />
  </Panel>
);

export const RoutesController = createBrowserRouter([
  {
    path: '/',
    element: (
      <Panel hideHeader>
        <OffersRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: '/:code', // Dynamic route to match any code pattern
    element: <ShortCodePage />, // Render ShortCodePage for this route
    loader: handleShortCode // Use handleShortCode as the loader function
  },
  {
    path: '/menu',
    element: (
      <Panel disableLegal hideHeader>
        <MenuRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'map',
    element: (
      <>
        <PageMeta title="Map" description={`${appEnv.name} map page`} />
        <Panel hidePanel>
          <MapRoute />
        </Panel>
      </>
    ),
    errorElement
  },
  {
    path: 'about',
    element: (
      <Panel headerText={'About'}>
        <AboutRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'contact',
    element: (
      <Panel disableLegal headerText={'Contact'}>
        <ContactRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'search',
    element: (
      <Panel hidePanel>
        <OffersRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'places/:id',
    element: <PlacesPanel />,
    errorElement
  },
  {
    path: 'people/:id',
    element: <PeoplePanel />,
    errorElement
  },
  {
    path: 'profile',
    element: (
      <Panel headerText={'Profile'}>
        <ProfileRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'profile/activity/:activity',
    element: (
      <Panel>
        <ActivityDescriptionRoute slicer="profile" setStoreContent={set_profile_activity} />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'filter',
    element: (
      <Panel headerText="Filters">
        <FilterRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'filter/activity/:activity',
    element: (
      <Panel>
        <ActivityDescriptionRoute slicer="filter" setStoreContent={set_filter_activity} />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'login',
    element: (
      <Panel>
        <LoginRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'register',
    element: (
      <Panel>
        <RegisterRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'settings',
    element: (
      <Panel disableLegal headerText={'Settings'}>
        <SettingsRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'mail',
    element: (
      <Panel headerText={'Mail'}>
        <MailRoute />
      </Panel>
    ),
    errorElement
  },

  {
    path: 'stats',
    element: (
      <Panel headerText={'Stats'}>
        <StatsRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'api/docs',
    element: (
      <Panel headerText={'API Docs'}>
        <DocsRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'premium',
    element: (
      <Panel headerText={'Premium'}>
        <PremiumRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'legal',
    element: (
      <Panel headerText={'Legal'}>
        <LegalRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'legal/eula',
    element: (
      <Panel disableLegal headerText={'Legal'}>
        <EulaRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'legal/privacy',
    element: (
      <Panel disableLegal headerText={'Legal'}>
        <PrivacyPolicyRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'legal/terms',
    element: (
      <Panel disableLegal headerText={'Legal'}>
        <TermsRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'legal/law/:id',
    element: (
      <Panel disableLegal headerText={'Local Law'}>
        <LawRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'help',
    element: (
      <Panel headerText={'Help'}>
        <HelpRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'event/:id',
    element: (
      <Panel headerText={'Event'}>
        <EventRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'edu',
    element: (
      <Panel headerText={'Education'}>
        <EduRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'edu/:id',
    element: (
      <Panel headerText={'Education'}>
        <EduRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: 'now',
    element: (
      <Panel headerText={'NOW'}>
        <NowRoute />
      </Panel>
    ),
    errorElement
  },
  {
    path: '/error',
    element: (
      <Panel headerText={'Error'}>
        <ErrorRoute message={'Page not found!'} />
      </Panel>
    ),
    errorElement
  }
]);
