// general react imports
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

// project specific files

// css files
import 'styles/sidebar.css';

type ModuleIconButtonType = {
  icon: ReactNode;
  link?: string;
  onClick?: () => void;
  padding?: string;
  margin?: string;
  className?: string;
};

export const ModuleIconButton: React.FunctionComponent<ModuleIconButtonType> = (props) => {
  const navigate = useNavigate();

  return (
    <IconButton
      aria-labelledby={`icon button ${props.link}`}
      size="large"
      onClick={props.onClick ? props.onClick : () => (props.link ? navigate(props.link) : {})}
      sx={{ padding: props.padding || '10px', margin: props.margin || 0 }}
      color="primary"
      {...props}
    >
      {props.icon}
    </IconButton>
  );
};
