/* eslint-disable */
import { handleRequest } from 'networking/requests';
import { ENDPOINTS } from './endpoints';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';

export const addUserCookies = (response: AxiosResponse) => {
  // save cookies from response
  for (const name of ['csrftoken', 'sessionid']) {
    Cookies.set(name, response.data[name], {
      sameSite: 'none',
      secure: true,
      path: '/'
    });
  }
};

export const account = {
  logIn: ({
    phone,
    password,
    handleResponse = (_response: AxiosResponse) => {}
  }: {
    phone: string;
    password: string;
    handleResponse?: (arg0: AxiosResponse) => any;
  }) =>
    handleRequest({
      endpoint: ENDPOINTS.USER.LOGIN,
      method: 'POST',
      data: { phone, password },
      handleResponse
    }),
  logOut: ({
    handleResponse = (_response: AxiosResponse) => {}
  }: {
    handleResponse?: (arg0: AxiosResponse) => any;
  }) =>
    handleRequest({
      endpoint: ENDPOINTS.USER.LOGOUT,
      handleResponse
    }),
  signUp: ({
    username,
    birthday,
    phone,
    email,
    password,
    handleResponse = (_response: AxiosResponse) => {}
  }: {
    username: string;
    birthday: Date;
    phone: string;
    email: string;
    password: string;
    handleResponse?: (arg0: AxiosResponse) => any;
  }) =>
    handleRequest({
      endpoint: ENDPOINTS.USER.REGISTER,
      method: 'POST',
      data: { username, birthday, phone, email, password },
      handleResponse
    }),
  token: async () =>
    Cookies.get('csrftoken') ??
    handleRequest({
      endpoint: ENDPOINTS.USER.TOKEN,
      handleResponse: (response: AxiosResponse) => addUserCookies(response)
    })
};
