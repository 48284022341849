// general react imports
import { createSlice } from '@reduxjs/toolkit';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { ModuleDataTypeArray } from 'types/data.d';
import { Range, StatsState } from 'types/stats.d';

const initialState: StatsState = {
  range: [{ label: '20 km' }],
  activity: [{ label: 'oral' }, { label: 'handjob' }, { label: 'vaginal' }]
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    set_stats_range: (state, action: PayloadAction<Range>) => {
      state.range = action.payload;
    },
    set_stats_activity: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.activity = action.payload;
    }
  }
});

export const { set_stats_range, set_stats_activity } = statsSlice.actions;
export const statsReducer = statsSlice.reducer;
