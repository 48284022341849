// general react imports
import React from 'react';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

// project specific files
import { ModuleColumn } from 'components/containers/module_column';
import { ModuleText } from 'components/text/module_text';

export const AdUseCash = () => {
  return (
    <ModuleColumn>
      <ModuleText upperCase>Use cash only!</ModuleText>
      <CurrencyExchangeIcon sx={{ height: '150px', width: '150px' }} />
      <ModuleText>Good workers never ask for CREDIT CARDS!</ModuleText>
    </ModuleColumn>
  );
};
