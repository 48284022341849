// general react imports
import React from 'react';

// project specific files
import { SocialMediaSupportedType } from './social_media_payload';
import { icons_manager } from 'components/icons/icons_manager';

export const getSocialMediaIcon = (social_media: SocialMediaSupportedType) => {
  switch (social_media) {
    case 'facebook':
      return <icons_manager.social.facebook />;
    case 'tiktok':
      return <icons_manager.social.tiktok />;
    case 'reddit':
      return <icons_manager.social.reddit />;
    case 'instagram':
      return <icons_manager.social.instagram />;
    case 'twitter':
      return <icons_manager.social.twitter />;
    case 'youtube':
      return <icons_manager.social.youtube />;
    case 'mail':
      return <icons_manager.mail.all />;
    default:
      throw Error(`social media icon not found: ${social_media}`);
  }
};

export const TikTokIcon = ({ color = '#EA638B' }: { color?: string }) => (
  <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" height="22px">
    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
  </svg>
);
