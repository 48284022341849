// general react imports
import { createSlice } from '@reduxjs/toolkit';

export interface LegalState {
  visibility: boolean;
}

const initialState: LegalState = {
  visibility: true
};

export const legalSlice = createSlice({
  name: 'legal',
  initialState,
  reducers: {
    hide_legal: (state) => {
      state.visibility = false;
    },
    show_legal: (state) => {
      state.visibility = true;
    }
  }
});

export const { hide_legal, show_legal } = legalSlice.actions;
export const legalReducer = legalSlice.reducer;
