// general react imports
import React, { useState, useRef, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Stack } from '@mui/material';
import { SwiperRef } from 'swiper/react';

// project specific files
import { LegalState, hide_legal } from 'redux/slicers/legal_slicer';
import { show_announcement } from 'redux/slicers/announcement_slicer';
import { ModuleDialog } from 'components/containers/module_dialog';
import { WarningCard } from 'components/legal/warning_card';
import { LawCard } from 'components/legal/law_card';
import { LanguageSelect } from 'components/settings/language_select';
import { CountrySelect } from 'components/settings/country_select';
import { AcceptDeclineButtons } from 'components/buttons/accept_decline_buttons';
import { PrivacyPolicyCard } from 'components/legal/privacy_policy_card';
import { TermsCard } from 'components/legal/terms_card';
import { EulaCard } from 'components/legal/eula_card';
// import { ToggleColorMode } from "components/settings/light_mode_button";
import { ModuleButton } from 'components/buttons/module_button';
import { SettingState } from 'types/setting.d';
import {
  ModuleCarousel,
  moveToNextSlide,
  moveToSlide
} from 'components/containers/module_carousel';
import { LegalHeader } from './legal_header';
import { ModuleText } from 'components/text/module_text';
import { ModuleColumn } from 'components/containers/module_column';
import { PriluviaLogoWithText } from 'components/priluvia/priluvia_logo_with_text';
import { ModuleButtonNoBorder } from 'components/buttons/module_button_no_border';
import { showToastError } from 'components/toast/toast';

export const LegalPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const swiperRef = useRef<Ref<SwiperRef>>(null);
  const visibility = useSelector(
    (state: { [key: string]: LegalState }) => state['legal']!.visibility
  );
  const [selected_index, setSelectedIndex] = React.useState(0);
  const country = useSelector(
    (state: { [key: string]: SettingState }) => state['settings']!.country[0]
  );
  const [accepted_legal, setAcceptedLegal] = useState(false);

  const moveBack = () => {
    const newIndex = selected_index - 1;
    setSelectedIndex(newIndex);
    moveToSlide(swiperRef, newIndex);
  };

  const moveForward = () => {
    const newIndex = selected_index + 1;
    setSelectedIndex(newIndex);
    moveToSlide(swiperRef, newIndex);
  };

  const ifCountryLegal = (method: () => void) => {
    if (country!.legal === 'no') {
      // show illegality popup
      showToastError('Please select legal country!');
      // move back to country select
      moveBack();
    } else {
      method();
    }
  };

  const handleClose = () => {
    // go back 1 page
    navigate(-1);
    // close tab (does not work in Chrome)
    window.close();
  };

  return (
    <ModuleDialog
      title={''}
      is_opened={visibility}
      handleClose={handleClose}
      actions={
        selected_index === 0 ? (
          <ModuleButton
            variant="contained"
            onClick={() => moveToNextSlide(swiperRef)}
            text={'Next'}
          />
        ) : selected_index === 1 ? (
          <AcceptDeclineButtons
            disabled={!accepted_legal}
            onAccept={moveForward}
            onDecline={handleClose}
          />
        ) : (
          <AcceptDeclineButtons
            href="filter"
            onAccept={() =>
              ifCountryLegal(() => {
                navigate('/filter');
                dispatch(hide_legal());
                dispatch(show_announcement());
              })
            }
            onDecline={handleClose}
          />
        )
      }
      header={
        <LegalHeader
          showBackArrow={selected_index !== 0}
          text={
            selected_index === 0 ? '' : selected_index === 1 ? 'Read the following:' : 'Warning!'
          }
          moveBack={() => moveBack()}
          close={() => handleClose()}
        />
      }
    >
      <ModuleCarousel
        swiperRef={swiperRef}
        setSelectedIndex={(index: number) => setSelectedIndex(index)}
      >
        <Stack direction="column" divider={<div style={{ height: '10px' }} />}>
          <PriluviaLogoWithText title="welcome to" />
          {/* <ToggleColorMode /> */}
          <LanguageSelect />
          <CountrySelect />
        </Stack>
        <Stack direction="column" sx={{ justifyContent: 'space-between', height: '100%' }}>
          <ModuleColumn justifyContent={'top'}>
            <LawCard />
            <EulaCard />
            <PrivacyPolicyCard />
            <TermsCard />
          </ModuleColumn>
          <ModuleButtonNoBorder
            onClick={() =>
              ifCountryLegal(() => {
                setAcceptedLegal(!accepted_legal);
              })
            }
          >
            <Checkbox
              aria-labelledby={`checkbox accepted_legal ${accepted_legal}`}
              checked={accepted_legal}
              sx={{ p: 0 }}
            />
            <div style={{ width: '10px' }} />
            <ModuleText className={'no-box-shadow'}>Accept all terms and policies</ModuleText>
          </ModuleButtonNoBorder>
        </Stack>
        <Stack direction="column" divider={<div style={{ height: '10px' }} />}>
          <WarningCard />
        </Stack>
      </ModuleCarousel>
    </ModuleDialog>
  );
};
