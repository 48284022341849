// general react imports
import React from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

// project specific files
import { ModuleButton } from 'components/buttons/module_button';

// css files
import 'styles/dialog.css';
import { ModuleIconButton } from 'components/buttons/module_icon_button';
import { ModuleRow } from 'components/containers/module_row';
import { ModuleText } from '../text/module_text';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor: 'primary.light',
  backdropFilter: 'blur(10px)',
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

type ModuleDialogType = {
  title: string;
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  text_cancel?: string;
  handleClose: () => void;
  is_opened: boolean;
  actions?: React.ReactNode;
  disableBackdrop?: boolean;
};

export const ModuleDialog: React.FunctionComponent<ModuleDialogType> = ({
  title,
  header,
  children,
  footer,
  text_cancel,
  handleClose,
  is_opened,
  actions,
  disableBackdrop
}) => {
  return (
    <BootstrapDialog
      disableEscapeKeyDown
      onClose={
        handleClose
          ? (_event, reason) => {
              if (!disableBackdrop || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
                handleClose();
              }
            }
          : () => {}
      }
      open={is_opened}
      fullWidth
      style={{
        overflow: 'hidden !important'
      }}
    >
      {header ? (
        header
      ) : (
        <ModuleRow>
          <ModuleIconButton icon={<div style={{ width: '45px' }}></div>} />
          <ModuleText sx={{ fontWeight: 'bold', color: 'primary.main' }}>
            {title?.toUpperCase()}
          </ModuleText>
          <ModuleIconButton icon={<CloseIcon />} onClick={handleClose} />
        </ModuleRow>
      )}
      <DialogContent>{children}</DialogContent>
      {footer}
      {actions ? (
        <DialogActions>{actions}</DialogActions>
      ) : (
        <DialogActions>
          <ModuleButton onClick={handleClose!} text={text_cancel ? text_cancel : 'Close'} />
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

ModuleDialog.defaultProps = {
  text_cancel: 'Close',
  disableBackdrop: true
};
