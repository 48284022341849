import { ModuleChip, ModuleChipList } from 'components/module/module_chip';
import React from 'react';
import { ModuleDataTypeArray } from 'types/data.d';

type DropdownGroupFilterType = {
  groupedSearch: Array<string>;
  groupedTag?: string;
  options: ModuleDataTypeArray;
  setSearchedOptions: (arg0: ModuleDataTypeArray) => void;
};

export const DropdownGroupFilter: React.FunctionComponent<DropdownGroupFilterType> = ({
  groupedSearch,
  groupedTag,
  options,
  setSearchedOptions
}) => {
  const [selectedSearchOptions, setSelectedSearchOptions] = React.useState<Array<string>>(['all']);
  const searchGroup = (keyword: string) => {
    const results =
      keyword === 'all'
        ? options
        : options.filter((option) =>
            String(option[groupedTag ? groupedTag : 'group']!).includes(keyword)
          );
    setSelectedSearchOptions([keyword]);
    setSearchedOptions(results);
  };

  return (
    <ModuleChipList>
      {groupedSearch.map((element: string) => (
        <React.Fragment key={element}>
          <ModuleChip
            label={element}
            checked={selectedSearchOptions.indexOf(element) !== -1}
            onClick={() => searchGroup(element)}
          />
        </React.Fragment>
      ))}
    </ModuleChipList>
  );
};
