// general react imports
import React from 'react';

// project specific files
import { ModuleTextType } from 'types/text.d';
import { ModuleText } from './module_text';

export const ModuleTextSmall: React.FunctionComponent<ModuleTextType> = (props) => {
  return <ModuleText size="small" {...props} />;
};
