// general react imports
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { icons_manager } from 'components/icons/icons_manager';

const toastClassName = 'gradient toast-popup';

export const showToastSuccess = (message: string) =>
  toast.success(message.toUpperCase(), {
    className: toastClassName,
    icon: <icons_manager.success />
  });
export const showToastWarning = (message: string) =>
  toast.warn(message.toUpperCase(), {
    className: toastClassName,
    icon: <icons_manager.warning />
  });
export const showToastError = (message: string) =>
  toast.error(message.toUpperCase(), {
    className: toastClassName,
    icon: <icons_manager.error />
  });
export const showToastInfo = (message: string) =>
  toast.info(message.toUpperCase(), {
    className: toastClassName,
    icon: <icons_manager.info />
  });

export const ToastController = () => (
  <ToastContainer
    className={'toast-controller'}
    closeButton={false}
    position="top-center"
    autoClose={5000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
  />
);
