// general react imports
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import MenuIcon from '@mui/icons-material/Menu';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import TransgenderIcon from '@mui/icons-material/Transgender';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import UpdateIcon from '@mui/icons-material/Update';
import { Facebook, Reddit, Twitter, Instagram, YouTube, Mail } from '@mui/icons-material';
import { TikTokIcon } from 'components/social_media/social_media_icons';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import ApiIcon from '@mui/icons-material/Api';
import StarBorderPurple500Outlined from '@mui/icons-material/StarBorderPurple500Outlined';
import WorkspacePremium from '@mui/icons-material/WorkspacePremium';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LanguageIcon from '@mui/icons-material/Language';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const icons_manager = {
  add: AddCircleOutlineIcon,
  api: ApiIcon,
  back: ArrowBackIcon,
  close: CloseIcon,
  copy: ContentCopyIcon,
  data: BarChartIcon,
  delete: DeleteForeverIcon,
  error: ReportOutlinedIcon,
  expand: ExpandMoreIcon,
  favourite: FavoriteIcon,
  filter: FilterAltOutlinedIcon,
  gender: { trans: TransgenderIcon, female: FemaleIcon, male: MaleIcon },
  info: InfoIcon,
  left: KeyboardArrowLeftIcon,
  link: ShareIcon,
  location: MyLocationIcon,
  login: AccountCircleOutlinedIcon,
  mail: { all: Mail, empty: RemoveCircleOutlineIcon },
  map: MapIcon,
  menu: MenuIcon,
  network: {
    offline: WifiOffIcon,
    online: WifiIcon,
    slow: SignalCellularConnectedNoInternet1BarIcon
  },
  offers: StarBorderIcon,
  question: QuestionMarkIcon,
  people: {
    not_found: NoAccountsIcon
  },
  photo: {
    add: AddAPhotoIcon,
    off: NoPhotographyIcon
  },
  place: {
    apartment: HomeIcon,
    building: ApartmentIcon,
    city: FlagIcon,
    district: FlagIcon,
    state: FlagIcon,
    not_found: ExploreOffIcon
  },
  premium: WorkspacePremium,
  profile: AccountCircleIcon,
  register: PersonAddAltIcon,
  report: FlagOutlinedIcon,
  right: KeyboardArrowRightIcon,
  search: SearchIcon,
  settings: SettingsOutlinedIcon,
  social: {
    facebook: Facebook,
    tiktok: TikTokIcon,
    reddit: Reddit,
    instagram: Instagram,
    twitter: Twitter,
    youtube: YouTube
  },
  star: { empty: StarBorderPurple500Outlined, full: StarIcon },
  success: CheckCircleOutlineIcon,
  visible: {
    on: VisibilityIcon,
    off: VisibilityOffIcon
  },
  update: UpdateIcon,
  warning: ReportProblemOutlinedIcon,
  website: LanguageIcon
};
