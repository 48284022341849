// general react imports
import { createSlice } from '@reduxjs/toolkit';

// project specific imports
import { PlaceType } from 'types/place.d';

const initialState: PlaceType = {
  id: '1',
  name: 'TEMP',
  type: 'Apartment',
  short: 'AAAA-AAAA',
  time: [
    {
      label: '9:30'
    }
  ],
  places: [],
  verified: true,
  website: [],
  activity: [],
  age: [],
  body_type: [],
  cup_size: [],
  ethnicity: [],
  i_am: [],
  looking_for: [],
  hair: [],
  height: [],
  language: [],
  penis_size: [],
  photos: [],
  people: []
};

export const placesSlice = createSlice({
  name: 'places',
  initialState: { payload: initialState },
  reducers: {
    set_places_content: (state, action) => {
      state.payload = action.payload.payload;
    }
  }
});

export const { set_places_content } = placesSlice.actions;
export const placesReducer = placesSlice.reducer;
