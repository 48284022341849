// project specific files
import { ModuleDataType, ModuleDataTypeArray } from 'types/data.d';

export const arrayToString = (
  array: ModuleDataTypeArray | ModuleDataType,
  property: string,
  max_length: number
): string => {
  if (Array.isArray(array)) {
    const temp: Array<string | string[] | number | undefined> = array.map(
      (element) => element[property]
    );
    let resultString = [...temp].sort().slice(0, max_length).join(', ');

    if (array.length - max_length > 0) {
      resultString = resultString + ', +' + (array.length - max_length) + ' other';
    }

    return resultString;
  } else {
    return String(array[property]);
  }
};
