// general react imports
import { createSlice } from '@reduxjs/toolkit';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { OfferState } from 'types/offer.d';

const initialState: OfferState = {
  payload: [
    // {
    //   label: 'San Francisco',
    //   photo:
    //     'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    //   id: '1',
    //   activities: [{ label: 'vaginal' }, { label: 'oral' }]
    // },
  ],
  filter_favourites: false
};

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    set_offers_payload: (state, action: PayloadAction<OfferState>) => {
      state.payload = action.payload.payload;
    },
    set_filter_favourites: (state, action: PayloadAction<boolean>) => {
      state.filter_favourites = action.payload;
    },
    negate_filter_favourites: (state) => {
      state.filter_favourites = !state.filter_favourites;
    }
  }
});

export const { set_offers_payload, set_filter_favourites, negate_filter_favourites } =
  offersSlice.actions;
export const offersReducer = offersSlice.reducer;
