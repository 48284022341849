// general react imports
import React from 'react';
import { Stack } from '@mui/system';

// project specific files
import { Logo } from 'components/logo/logo';
import { ModuleText } from 'components/text/module_text';
import { appEnv } from 'app_env';

export const PriluviaLogoWithText = ({
  title,
  logo,
  text
}: {
  title?: string;
  logo?: React.ReactNode;
  text?: string;
}) => {
  return (
    <Stack direction="column" divider={<div style={{ height: '10px' }} />}>
      {title ? (
        <ModuleText upperCase sx={{ textAlign: 'center' }}>
          {title}
        </ModuleText>
      ) : null}
      <Stack
        direction="row"
        sx={{
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center'
        }}
      >
        {logo ? logo : <Logo height={'50px'} />}
        <ModuleText sx={{ marginLeft: '10px', fontSize: 40 }}>
          {text ? text : appEnv.name}
        </ModuleText>
      </Stack>
    </Stack>
  );
};
