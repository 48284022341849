// general react imports
import React from 'react';
import { useSelector } from 'react-redux';

// project specific files
import { SettingState } from 'types/setting.d';
import { ModuleButtonNavigate } from 'components/priluvia/priluvia_button_navigate';
import { LegalIcon } from 'components/legal/legal_icon';

export const LawCard = () => {
  const country = useSelector((state: { [key: string]: SettingState }) => {
    return state['settings']!.country[0];
  });

  return (
    <ModuleButtonNavigate
      text={`Local law: ${country!.code}`}
      icon={<LegalIcon choice={country!.legal} />}
      link={`/legal/law?id=${country!.code}`}
    />
  );
};
