// general react imports
import { createSlice } from '@reduxjs/toolkit';

// types
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReportPersonState, ReportState } from 'types/report.d';

const initialState: ReportState = {
  visibility: false,
  person: {
    id: '',
    name: ''
  },
  reported_list: []
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    show_report: (state) => {
      state.visibility = true;
    },
    hide_report: (state) => {
      state.visibility = false;
    },
    add_to_reported_list: (state, action: PayloadAction<string>) => {
      state.reported_list = [...state.reported_list, action.payload];
    },
    set_report_person: (state, action: PayloadAction<ReportPersonState>) => {
      state.person = action.payload;
    }
  }
});

export const { show_report, hide_report, add_to_reported_list, set_report_person } =
  reportSlice.actions;

export const reportReducer = reportSlice.reducer;
