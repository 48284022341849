// general react imports
import React from 'react';

// project specific files
import { ModuleIconButton } from 'components/buttons/module_icon_button';
import { ModuleRow } from 'components/containers/module_row';
import { ModuleText } from 'components/text/module_text';
import { icons_manager } from 'components/icons/icons_manager';

export const LegalHeader = ({
  text,
  moveBack,
  close,
  showBackArrow
}: {
  text: string;
  moveBack: () => void;
  close: () => void;
  showBackArrow?: boolean;
}) => {
  return (
    <ModuleRow>
      {showBackArrow ? <ModuleIconButton icon={<icons_manager.back />} onClick={moveBack} /> : null}
      <ModuleText upperCase sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        {text}
      </ModuleText>
      <ModuleIconButton icon={<icons_manager.close />} onClick={close} />
    </ModuleRow>
  );
};
