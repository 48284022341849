// general react imports
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// project specific files
import { print } from 'functions/print';
import { useServiceWorker } from './useServiceWorker';
import { showToastSuccess, showToastWarning } from 'components/toast/toast';
import { clearCacheData } from 'redux/store';

const meta = {
  date_modified_old: new Date().toUTCString(),
  timeout: {
    on_app_reload: 5_000,
    on_update_check: 1 * 60 * 1000
  },
  url: `${window.location.origin}/manifest.json`
};

export const reloadApp = () => {
  // notify on update
  const message = 'A new version of app is available! Reloading app...';
  print.info(message);
  showToastWarning(message);
  // clear cache
  clearCacheData();
  // reload page in 5 seconds
  setTimeout(() => {
    window.location.reload();
  }, meta.timeout.on_app_reload);
};

export const checkForUpdate = async (
  notifyOnNoUpdate = false,
  disableAutoReload = false,
  onUpdateAvailable = () => {}
) =>
  axios({
    url: meta.url,
    method: 'get',
    headers: {
      'If-Modified-Since': String(meta.date_modified_old)
    },
    timeout: meta.timeout.on_update_check - 10_000
  })
    .then((response) => {
      if (response.status < 304) {
        // update found!
        if (onUpdateAvailable) {
          onUpdateAvailable;
        }
        if (!disableAutoReload) {
          reloadApp();
        }
      } else {
        // no update
        if (notifyOnNoUpdate) {
          showToastSuccess('App is up to date!');
        }
      }
    })
    .catch((error) =>
      error.request.status !== 304
        ? print.error('Error while checkForUpdate:', error)
        : notifyOnNoUpdate
          ? showToastSuccess('App is up to date!')
          : null
    );

export const AppUpdate = () => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState<boolean>(false);
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    // check for updates periodically
    const timer = setInterval(() => {
      if (!isUpdateAvailable) {
        checkForUpdate(false, false, () => setIsUpdateAvailable(true));
      }
    }, meta.timeout.on_update_check);

    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (showReload && waitingWorker) {
      reloadApp();
    }
  }, [waitingWorker, showReload, reloadPage]);

  return <></>;
};
