// general react imports
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// project specific
import { FilterState } from 'types/filter.d';
import { ModuleDataTypeArray } from 'types/data.d';
import { BodyType, CupSize, Ethnicity, GenderArray, Hair } from 'types/person.d';
import { activities } from 'payloads/activities';
import { languages } from 'payloads/languages';

const initialState: FilterState = {
  activity: activities.map((x) => ({ label: x.label })),
  age: [{ label: 20 }, { label: 40 }],
  body_type: [{ label: 'thicc' }, { label: 'regular' }, { label: 'thin' }],
  cup_size: [
    { label: 'A' },
    { label: 'B' },
    { label: 'C' },
    { label: 'D' },
    { label: 'E' },
    { label: 'F' },
    { label: 'G' }
  ],
  ethnicity: [
    { label: 'white' },
    { label: 'black' },
    { label: 'latino' },
    { label: 'arabian' },
    { label: 'indian' },
    { label: 'asian' },
    { label: 'exotic' }
  ],
  i_am: [{ label: 'male' }],
  looking_for: [{ label: 'female' }],
  hair: [{ label: 'black' }, { label: 'brown' }, { label: 'blond' }, { label: 'red' }],
  height: [{ label: 150 }, { label: 200 }],
  language: languages.map((x) => ({ label: x.label })),
  penis_size: [{ label: 5 }, { label: 30 }]
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    set_filter_activity: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.activity = action.payload;
    },
    set_filter_age: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.age = action.payload;
    },
    set_filter_body_type: (state, action: PayloadAction<BodyType>) => {
      state.body_type = action.payload;
    },
    set_filter_cup_size: (state, action: PayloadAction<CupSize>) => {
      state.cup_size = action.payload;
    },
    set_filter_ethnicity: (state, action: PayloadAction<Ethnicity>) => {
      state.ethnicity = action.payload;
    },
    set_filter_i_am: (state, action: PayloadAction<GenderArray>) => {
      state.i_am = action.payload;
    },

    set_filter_looking_for: (state, action: PayloadAction<GenderArray>) => {
      state.looking_for = action.payload;
    },
    set_filter_hair: (state, action: PayloadAction<Hair>) => {
      state.hair = action.payload;
    },
    set_filter_height: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.height = action.payload;
    },
    set_filter_language: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.language = action.payload;
    },
    set_filter_penis_size: (state, action: PayloadAction<ModuleDataTypeArray>) => {
      state.penis_size = action.payload;
    }
  }
});

export const {
  set_filter_activity,
  set_filter_age,
  set_filter_body_type,
  set_filter_cup_size,
  set_filter_ethnicity,
  set_filter_i_am,
  set_filter_looking_for,
  set_filter_hair,
  set_filter_height,
  set_filter_language,
  set_filter_penis_size
} = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
