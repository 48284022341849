// general react imports
import React from 'react';
import { Stack, Chip, SxProps } from '@mui/material';
import { ScrollView } from 'react-native';

type ModuleChipType = {
  label: string;
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
} & React.HTMLProps<HTMLDivElement>;
type ModuleChipListType = {
  children: React.ReactNode;
  className?: string;
  sx?: any;
} & React.HTMLProps<HTMLDivElement>;

export const ModuleChip: React.FunctionComponent<ModuleChipType> = ({
  label,
  checked,
  disabled = false,
  onClick,
  sx
}) => {
  return (
    <Chip
      label={label}
      color="primary"
      disabled={disabled}
      variant={checked ? 'filled' : 'outlined'}
      onClick={onClick}
      sx={sx ? sx : null}
    />
  );
};

export const ModuleChipList: React.FunctionComponent<ModuleChipListType> = ({
  children,
  className,
  sx
}) => {
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        minHeight: '30px',
        marginTop: '8px',
        marginBottom: '8px',
        ...sx
      }}
    >
      <Stack
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={1}
        className={className ? className : ''}
        sx={{
          padding: 0,
          width: '100%',
          overflowX: 'auto'
        }}
      >
        {children}
      </Stack>
    </ScrollView>
  );
};
