// general react imports
import React from 'react';

// project specific files
import { ModuleButton } from 'components/buttons/module_button';
import { ModuleRow } from 'components/containers/module_row';

type AcceptDeclineButtonsType = {
  disabled?: boolean;
  onAccept: () => void;
  onDecline: () => void;
} & React.HTMLProps<HTMLDivElement>;

export const AcceptDeclineButtons: React.FunctionComponent<AcceptDeclineButtonsType> = ({
  disabled,
  onAccept,
  onDecline
}) => {
  return (
    <ModuleRow>
      <ModuleButton
        disabled={disabled ? disabled : false}
        onClick={onAccept}
        text={'ACCEPT'}
        variant="contained"
      />
      <ModuleButton onClick={onDecline} text={'DECLINE'} />
    </ModuleRow>
  );
};
