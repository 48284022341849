// general react imports
import React, { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, EffectFade, Navigation, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'styles/carousel.css';

type ModuleCarouselType = {
  children: ReactNode;
  navigation?: boolean;
  setSelectedIndex?: (arg0: any) => any;
  swiperRef?: any;
  disableSwipe?: boolean;
  height?: string;
  pagination?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const moveToNextSlide = (swiperRef: any) => {
  if (swiperRef!.current) {
    swiperRef!.current.swiper.slideNext();
  }
};

export const moveToSlide = (swiperRef: any, index: number) => {
  if (swiperRef!.current) {
    swiperRef!.current.swiper.slideTo(index);
  }
};

export const ModuleCarousel: React.FunctionComponent<ModuleCarouselType> = ({
  children,
  navigation,
  setSelectedIndex,
  swiperRef,
  disableSwipe = false,
  height = '100%',
  pagination = false
}) => {
  return (
    <Swiper
      ref={swiperRef ? swiperRef : null}
      keyboard={{
        enabled: true
      }}
      pagination={pagination}
      slidesPerView={1}
      spaceBetween={30}
      centeredSlides={true}
      navigation={navigation ? navigation : false}
      modules={[Keyboard, EffectFade, Navigation, Pagination]}
      speed={600}
      style={{
        height: height,
        width: '100%'
      }}
      preventInteractionOnTransition={true}
      allowTouchMove={!disableSwipe}
      effect={'coverflow'}
      grabCursor={disableSwipe}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true
      }}
      onSlideChange={(swiperCore) => {
        if (setSelectedIndex) {
          const { activeIndex } = swiperCore;
          setSelectedIndex(activeIndex);
        }
      }}
    >
      {React.Children.map(children, (child) => (
        <SwiperSlide style={{ backgroundColor: 'primary.main' }}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};
